import { Pipe, PipeTransform } from '@angular/core';
import { cleanTitle } from 'src/app/_helpers/string-hypenator';

@Pipe({ name: 'seoString' })
export class SEOStringPipe implements PipeTransform {
  transform(submittedTitle: string): string {
    return cleanTitle(submittedTitle);
  }
}


