<!-- <div class="type-list filters"> -->
<div  *ngIf="state"  class="search-box-container">
    <input matInput  
  type="text"
  #input
  (keyup.enter)="this.search(input.value)"
  [value]="this.state.query"
  class="search-box"
/>
<div ngClass="search-button">
    <button mat-icon-button (click)="this.search(input.value)"><mat-icon>search</mat-icon></button>
</div>
</div>

<!-- (keyup.enter)="this.state.refine(input.value)" --> 