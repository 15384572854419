import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-short-page-layout',
  templateUrl: './short-page-layout.component.html',
  styleUrls: ['./short-page-layout.component.scss'],
})
export class ShortPageLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
