import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeedbackService } from '../../core/services/feedback.service';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  feedbackType: string;

  feedbackTypes: string[] = ['Product suggestion', 'Something we are doing well', 'Something we can improve', 'Other'];

  feedbackForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<FeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    public feedbackService: FeedbackService,
    public auth: AuthService,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.feedbackForm = this.formBuilder.group({
      type: ['', [Validators.required]],
      // description: ['', [Validators.maxLength(250)]],
      message: [''],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCancel(): void {
    this.dialogRef.close();
    this.feedbackService.feedbackClosed();
  }

  async onSubmit() {
    // this.submitted = true;
    // console.log(this.feedbackForm.value);
    // this.feedbackService._loading.next(true);
    // stop here if form is invalid
    if (this.feedbackForm.invalid) {
      return;
    }
    this.feedbackService.submitFeedback(this.feedbackForm.value).then(result => {
      this.onCancel();
      const action = 'Dismiss';
      let message: string;
      if (result) {
        message = 'Feedback received. Thank you.';
        this.snackBar.open(message, action);
      }
    });
  }
}
