<div *ngIf="!breakpointService.isMobile; else mobile" class="{{'gallery-' + id}} gallery {{'grid-' + length}}"
  ngClass.xs="xs" itemscope itemtype="http://schema.org/ImageGallery">
  <figure *ngFor="let image of displayedImages" itemprop="associatedMedia" itemscope
    itemtype="http://schema.org/ImageObject"
    (click)="openPhotoSwipe($event, image.id, 'gallery-' + id); $event.stopPropagation()">
    <a href="{{image.src}}" itemprop="contentUrl">
      <img src="{{image.msrc}}" itemprop="thumbnail" alt="Image description" />
    </a>
  </figure>
  <button mat-flat-button *ngIf="moreImages" class="morePhotosButton"
    (click)="openPhotoSwipe($event, displayedImages[0].id, 'gallery-' + id); $event.stopPropagation()">View all
    photos</button>
</div>

<ng-template #mobile>
  <div *ngIf="images.length > 1; else oneImage" class="swiper {{'gallery-' + id}}" (click)="$event.stopPropagation()" itemscope
    itemtype="http://schema.org/ImageGallery">
    <swiper [slidesPerView]="1" [spaceBetween]="50" (swiper)="onSwiper($event)" (slideChange)="onSlideChange()"
      [navigation]="true" [pagination]="{ clickable: true, dynamicBullets: true, dynamicMainBullets: 3 }">
      <ng-template *ngFor="let image of images" swiperSlide>
        <figure itemprop="associatedMedia" itemscope itemtype="http://schema.org/ImageObject"
          (click)="openPhotoSwipe($event, image.id, 'gallery-' + id)">
          <a href="{{image.src}}" itemprop="contentUrl">
            <img src="{{image.msrc}}" itemprop="thumbnail" alt="Image description" />
          </a>
        </figure>
      </ng-template>
    </swiper>
  </div>
</ng-template>


<ng-template #oneImage>
  <div class="swiper {{'gallery-' + id}} gallery {{'grid-' + length}}" ngClass.xs="xs" itemscope
    itemtype="http://schema.org/ImageGallery" (click)="$event.stopPropagation()">
    <figure *ngFor="let image of displayedImages" itemprop="associatedMedia" itemscope
      itemtype="http://schema.org/ImageObject" (click)="openPhotoSwipe($event, image.id, 'gallery-' + id)">
      <a href="{{image.src}}" itemprop="contentUrl">
        <img src="{{image.msrc}}" itemprop="thumbnail" alt="Image description" />
      </a>
    </figure>
    <button mat-flat-button *ngIf="moreImages" class="morePhotosButton"
      (click)="openPhotoSwipe($event, displayedImages[0].id, 'gallery-' + id)">View all photos</button>
  </div>
</ng-template>