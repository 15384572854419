import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
// import algoliasearch from 'algoliasearch/lite';
// import { environment } from '../../../environments/environment';
import { BuildService } from '../../core/services/build.service';
import { Build } from '../../core/models/build';
import { AuthService } from '../../core/services/auth.service';
// import { SearchOverlayService } from '../../core/services/search-overlay.service';
import { FeedbackComponent } from '../feedback/feedback.component';
import { SignupModalComponent } from '../../account/signup-modal/signup-modal.component';
import { BuildPickerSheetComponent } from '../build-picker-sheet/build-picker-sheet.component';

// const searchClient = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);

@Component({
  selector: 'app-user-auth',
  templateUrl: './user-auth.component.html',
  styleUrls: ['./user-auth.component.scss'],
})
export class UserAuthComponent implements OnInit {
  numberOfBuilds: number;

  builds: Build[];

  userId: string;

  hasBuilds: boolean;

  buttons = [];

  query = '';

  constructor(
    public auth: AuthService,
    private router: Router,
    // public search: SearchService,
    // private searchOverlay: SearchOverlayService,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private buildService: BuildService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.auth.getUser().then(async user => {
      if (user) {
        // console.log('user is', user);
        this.userId = user.uid;
        await this.buildService
          .getBuildsforUser(this.userId)
          .then(builds => {
            this.builds = builds;
            this.numberOfBuilds = this.builds.length;
            if (this.numberOfBuilds > 0) {
              this.hasBuilds = true;
            } else {
              this.hasBuilds = false;
            }
          })
          .then(() => {
            this.setButtons(this.numberOfBuilds);
          });
      }
    });
  }

  setButtons(buildsNumber: number): void {
    switch (buildsNumber) {
      case 0:
        this.buttons = [
          {
            icon: 'add',
            link: 'create/build',
            label: 'New build',
            command: 'build',
          },
        ];
        break;
      default:
        this.buttons = [
          {
            icon: 'add',
            link: 'create/build',
            label: 'New build',
            command: 'build',
          },
          {
            icon: 'add',
            link: 'step-form',
            label: 'Add a step',
            command: 'step',
          },
        ];
        break;
    }
  }

  logout(): void {
    this.auth.logout();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  goHome(): void {
    this.router.navigate(['']);
  }

  openFeedback(): void {
    this.dialog.open(FeedbackComponent, {
      width: '450px',
      // disableClose: true,
      data: 'hello',
    });
  }

  openSignUpModal(): void {
    const dialogRef = this.dialog.open(SignupModalComponent, {
      width: '600px',
    });
  }

  handleClick(command: string): void {
    switch (command) {
      case 'step':
        if (this.numberOfBuilds > 1) {
          this._bottomSheet.open(BuildPickerSheetComponent, {
            data: { builds: this.builds },
          });
        } else {
          this.router.navigate([`create/step/${this.builds[0].id}`]);
        }
        break;
      case 'build':
        this.router.navigate([`create/build`]);
        break;

      default:
        this.router.navigate([`create/build`]);
        break;
    }
  }
}
