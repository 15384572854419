import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { SeoService } from '../../core/services/seo.service';
import { AuthService } from '../../core/services/auth.service';
import { BuildService } from '../../core/services/build.service';
import { Build } from '../../core/models/build';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  error: any;

  isLoaded: boolean;

  featuredBuild: Observable<Build[]>;

  recentBuilds: Observable<Build[]>;

  relativeDate: DateTime;

  year: number;

  constructor(public buildService: BuildService, public auth: AuthService, private router: Router, private title: Title, private seo: SeoService) { }

  ngOnInit(): void {
    // Get the current year for the "footer" copyright
    this.relativeDate = DateTime.local();
    this.year = this.relativeDate.year;

    // console.log(this.auth.user$);
    this.seo.insertSchema(SeoService.websiteSchema())
    this.seo.insertSchema(SeoService.orgSchema(), 'structured-data-org')
    this.seo.generateTags();
    this.title.setTitle(`BuildString | A community for build threads`);
    this.buildService.getRecentSteps();
    this.featuredBuild = this.buildService.getFeaturedBuild();
    this.recentBuilds = this.buildService.getRecentBuilds(4);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.seo.removeStructuredData()
  }

  googleLogin() {
    this.auth
      .googleSignIn()
      .then(result => {
        if (result) {
          this.router.navigate(['/']);
        }
      })
      .catch((err: Error) => {
        this.error = err;
      });
  }

  onScroll(e) {
    this.buildService.getMoreRecentSteps();
  }
}
