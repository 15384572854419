<footer class="site-footer mat-typography">
  <div class="col1">
    <a mat-button ngClass.xs="xs" class="homeButton" [routerLink]="['/']">
      <img class="logo" src="assets/img/logo.svg" alt="BuildString">
    </a>
  </div>
  <div class="col2">
    <p class="mat-small">
      <a routerLink="/about">About us</a><br>
      <a routerLink="/contact">Contact us</a><br>
      <a routerLink="/account/signup">Create an account</a>
    </p>
  </div>
  <div class="col3">
    <p class="mat-caption">
      <a routerLink="/tos">Terms of service</a><br>
      <a routerLink="/privacy">Privacy statement</a><br>
      <span>Copyright &copy; {{ year }} BuildString, All rights reserved.</span>
    </p>
  </div>
</footer>