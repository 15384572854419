<div id="fab-dismiss" *ngIf="fabTogglerState==='active'" (click)="onToggleFab()">
</div>
<div *ngIf="auth.user$ | async as user;" class="fab-container">
  <button mat-fab color="primary" class="fab-toggler" (click)="onToggleFab()">
    <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i> <!-- Animation here -->
  </button>
  <div [@speedDialStagger]="buttons.length">
    <!-- and here -->
    <div *ngFor="let btn of buttons" class="small-button">
      <span class="label">{{btn.label}}</span>
      <button mat-mini-fab class="fab-secondary" color="primary" (click)="handleClick(btn.command)">
        <i class="material-icons">{{btn.icon}}</i>
      </button>
    </div>
  </div>
</div>