import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss'],
})
export class SiteFooterComponent implements OnInit {
  relativeDate: DateTime;

  year: number;

  // constructor() {}

  ngOnInit() {
    this.relativeDate = DateTime.local();
    this.year = this.relativeDate.year;
    // console.log(this.year);
  }
}
