<!-- <div *ngIf="!feedbackService.loading | async"> -->

<h1 mat-dialog-title>What's on your mind?</h1>
<div mat-dialog-content>

    <form [formGroup]="feedbackForm" class="feedbackForm" ngClass.lt-sm="xs">
        <mat-radio-group aria-label="feedback types" class="type-radio-options" formControlName="type">
            <mat-radio-button class="type-radio-button" *ngFor="let type of feedbackTypes" [value]="type">
                {{type}}
            </mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="fill" class="message">
            <mat-label>Message</mat-label>
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="20" formControlName="message"></textarea>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button type="submit" color="primary" (click)="onSubmit()" [disabled]="!feedbackForm.valid">
        <mat-spinner color="accent" diameter="28" *ngIf="(feedbackService.loading | async); else defaultText">
        </mat-spinner>
    </button>
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
</div>

<ng-template #defaultText>Submit</ng-template>