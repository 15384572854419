import { Pipe, PipeTransform } from '@angular/core';
import { Build } from '../../core/models/build';

@Pipe({
  name: 'mainBuildImage',
})
export class MainBuildImagePipe implements PipeTransform {
  transform(value: Build, size: number, ...args: unknown[]): unknown {
    const stepImageBase = 'stepImageURL';
    const buildImageBase = 'downloadURL';
    // if the build is set to use the latest step image
    // also a step image must exist
    // Otherwise use the build image
    if (value.useStepImage) {
      if (value.stepImageURL200) {
        switch (size) {
          case 200:
            return value.stepImageURL200;
            break;
          case 400:
            return value.stepImageURL400;
            break;
          case 800:
            return value.stepImageURL800;
            break;
          default:
            return value.stepImageURL200;
            break;
        }
      }
    }
    switch (size) {
      case 200:
        return value.downloadURL200;
        break;
      case 400:
        return value.downloadURL400;
        break;
      case 800:
        return value.downloadURL800;
        break;
      default:
        return value.downloadURL200;
        break;
    }
  }
}
