<app-speed-dial-fab [ngStyle.gt-xs]="{'display':'none'}"></app-speed-dial-fab>
<div class="home-container" ngClass.xs="xs single-scroll" infiniteScroll [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="1000" (scrolled)="onScroll($event)">
    <!-- <div class="left" fxHide.xs="true"></div> -->
    <div class="right" ngClass.xs="xs">
        <mat-card *ngIf="!(this.auth.user$ | async)" class="signup-card mat-elevation-z0 noRadius">
            <h2>New to BuildString?</h2>
            <p>Sign up now to share what you're building.</p>
            <a mat-stroked-button color="white" (click)="googleLogin()">
                <img class="auth-google-logo" src="../../../assets/auth-providers/google.svg" alt="Google logo">
                <span>Sign up with Google</span>
            </a>
            <a mat-stroked-button color="white" [routerLink]="['/account/signup']">
                <mat-icon>email</mat-icon>
                <span>Sign up with email</span>
            </a>
        </mat-card>
        <ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-3214479096688532"
            data-ad-slot="4716231019" data-ad-format="auto" data-full-width-responsive="true"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
        <mat-card fxHide.xs="true" class="mat-elevation-z0 noRadius featured-build">
            <h2>Featured build thread</h2>
            <app-build-card *ngFor="let build of featuredBuild | async;" [build]="build">

            </app-build-card>
        </mat-card>
        <mat-card fxHide.xs="true" class="mat-elevation-z0 noRadius recent-builds">
            <h2>Recently started build threads</h2>
            <app-build-card *ngFor="let build of recentBuilds | async;" [build]="build">

            </app-build-card>
        </mat-card>
        <mat-card class="mat-elevation-z0 noRadius side-footer" fxHide.xs="true">
            <div class="col1">
                <img class="logo" src="assets/img/logo.svg" alt="BuildString">
            </div>
            <div class="col2">
                <p class="mat-small">
                    <a routerLink="/about">About us</a><br>
                    <a routerLink="/contact">Contact us</a><br>
                    <a routerLink="/account/signup">Create an account</a>
                </p>
            </div>
            <div class="col3">
                <p class="mat-caption">
                    <a routerLink="/tos">Terms of service</a><br>
                    <a routerLink="/privacy">Privacy statement</a><br>
                    <span>Copyright &copy; {{ year }} BuildString, All rights reserved.</span>
                </p>
            </div>
        </mat-card>
    </div>
    <div class="center" ngClass.xs="xs">
        <h1 fxHide.gt-xs="true">Recently updated</h1>
        <!-- <app-home-banner fxHide.gt-xs="true"></app-home-banner> -->
        <div class="step-item" *ngFor="let step of buildService.data | async;">
            <app-home-step [step]="step"></app-home-step>
        </div>
        <mat-spinner *ngIf="buildService.loading | async"></mat-spinner>
    </div>

</div>