<div class="page" ngClass.xs="xs" fullscreen>
  <header class="header" ngClass.xs="xs">
    <a mat-button ngClass.xs="xs" class="homeButton" [routerLink]="['/']">
      <img class="logo" src="assets/img/logo.svg" alt="BuildString">
    </a>
    <app-search fxTrue="true" fxHide.xs="true" class="search"></app-search>
    <button fxHide="true" fxShow.xs="true" class="search-button" mat-button (click)="toggleSearch()">
      <mat-icon aria-hidden="false" aria-label="Search build threads">search</mat-icon>
    </button>
    <app-user-auth fxTrue="true" fxHide.xs="true"></app-user-auth>
  </header>
  <div class="main-page content" ngClass.xs="xs">
    <!-- <main [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </main> -->
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
  <site-footer *ngIf="showFooter" fxShow="true" fxHide.xs="true"></site-footer>
  <footer fxHide="true" fxShow.xs="true">
    <app-user-auth class="xs"></app-user-auth>
  </footer>
</div>