import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShortPageLayoutComponent } from '../_layout/short-page-layout/short-page-layout.component';

import { ResultsComponent } from './results/results.component';

const searchRoutes: Routes = [
  {
    path: '',
    component: ResultsComponent,
    data: { title: 'Search Results' },
  }
];

@NgModule({
  imports: [RouterModule.forChild(searchRoutes)],
  exports: [RouterModule],
})
export class SearchRoutingModule { }
