<div class="step-detail" *ngIf="step" ngClass.xs="xs" (click)="goToStep(step.id, step.title)">
    <div class="info" *ngIf="this.stepOwner | async as stepOwner">
        <img class="avatar avatar48" *ngIf="stepOwner.profileURL100; else noAvatar" src="{{stepOwner.profileURL100}}"
            alt="{{stepOwner.displayName}}">
        <span class="name">{{ stepOwner.displayName }}</span>
        <span class="build-title mat-caption oneLine">{{ buildTitle }}</span>
        <p class="time mat-caption"><span
                matTooltip="{{step.creationTime.toMillis() | date: 'longDate'}}">{{step.creationTime.seconds
                |
                secondsToRelTime}}</span></p>
        <app-share-button class="share" [type]="'step'" [id]=step.id [title]=step.title></app-share-button>
    </div>

    <a class="title mat-h1" routerLink="/step/{{step.title | seoString}}.{{step.id}}">{{step.title}}</a>
    <app-gallery *ngIf="stepImages | async as stepImages" [id]=step.id [images]=stepImages class="gallery">
    </app-gallery>
    <div class="gallery-footer" routerLink="/step/{{step.title | seoString}}.{{step.id}}">
        <mat-icon class="material-icons-outlined comments-icon" aria-hidden="false" aria-label="Comments">comment
        </mat-icon>
        <span>{{ step.numberOfComments ? step.numberOfComments : '' }}</span>
    </div>
    <quill-view id="quill-view" class="step-description" [content]="step.description" format="object"
        [style]="{padding: '0px'}" [appTrimQuill]>
    </quill-view>

</div>
<app-lightbox></app-lightbox>
<ng-template #noAvatar>
    <img class="avatar48" src="assets/img/FPO-Image.png">
</ng-template>