import { User } from './user';

export enum NotificationTypes {
  Own,
  Follow,
  Participated,
}

export class Notification {
  toUser: User;

  fromUser: User;

  read: boolean;

  type: NotificationTypes;

  creationTime?: any;

  targetURL: string;
}
