import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

// import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { NotfoundComponent } from './notfound/notfound.component';
// import { BuildsListComponent } from './builds/builds-list/builds-list.component';
// import { ResultsComponent } from './search/results/results.component';
// import { RedirectGuard } from './core/guards/redirect.guard';
// import { RedirectComponent } from './redirect/redirect.component';
import { HomeComponent } from './builds/home/home.component';
// import { SurveySignupComponent } from './survey/survey-signup/survey-signup.component';
import { ShortPageLayoutComponent } from './_layout/short-page-layout/short-page-layout.component';

// https://github.com/angular/angularfire/issues/2099
const isVerifiedUser = () =>
  pipe(
    map((user: any) => {
      if (!user) {
        return ['account/signin'];
      }
      return user && user.emailVerified ? true : ['account/verification-needed'];
    }),
  );

export const routes: Routes = [
  // App routes goes here here
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { title: 'Home Page', footer: false },
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },


    ],
  },
  {
    path: '',
    component: ShortPageLayoutComponent,
    children: [
      {
        path: 'not-authorized',
        loadChildren: () => import('./not-authorized/not-authorized.module').then(m => m.NotAuthorizedModule),
      },
      {
        path: 'results',
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
      },

    ],
  },
  {
    path: '',
    children: [
      {
        path: 'account',
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
      },
    ],
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'build',
    loadChildren: () => import('./builds/build.module').then(m => m.BuildModule),
  },
  {
    path: 'step',
    loadChildren: () => import('./steps/step.module').then(m => m.StepModule),
  },
  {
    path: 'survey',
    loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule),
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'create',
    loadChildren: () => import('./create/create.module').then(m => m.CreateModule),
  },
  {
    path: 'edit',
    loadChildren: () => import('./edit/edit.module').then(m => m.EditModule),
  },
  {
    path: 'tos',
    loadChildren: () => import('./tos/tos.module').then(m => m.TosModule),
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then(m => m.PrivacyModule),
  },
  { path: 'signup', redirectTo: '/account/signup' },
  { path: 'signin', redirectTo: '/account/signin' },
  {
    path: '404',
    component: NotfoundComponent,
    data: { title: 'Page not found' },
  },
  { path: '**', redirectTo: '/404' },
];

const routerOptions: ExtraOptions = {
  // useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'ignore',
  // relativeLinkResolution: 'corrected',
  // ...any other options you'd like to use
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})

// export const routes: ModuleWithProviders = RouterModule.forRoot(router);
export class AppRoutingModule { }
