export class Step {
  id?: string;

  idLowercase?: string;

  title?: string;

  description?: any;

  uid?: string;

  displayName?: string;

  buildId?: string;

  imageId?: string;

  downloadURL200?: string;

  downloadURL400?: string;

  downloadURL800?: string;

  profileURL?: string;

  profileURL100?: string;

  profileURL200?: string;

  profileURL400?: string;

  lastUpdatedTime?: any;

  creationTime?: any;

  numberOfComments?: number;

  isPrivate?: boolean;
}
