<header class="header" ngClass.xs="xs">
    <a mat-button ngClass.xs="xs" class="homeButton" [routerLink]="['/']">
        <img class="logo" src="assets/img/logo.svg">
    </a>
    <app-user-auth fxTrue="true" fxHide.xs="true"></app-user-auth>
</header>
<router-outlet content></router-outlet>
<site-footer fxShow="true" fxHide.xs="true"></site-footer>
<footer fxHide="true" fxShow.xs="true">
    <app-user-auth></app-user-auth>
</footer>