<div class="search">
    <form [formGroup]="searchForm">
        <div class="container" matAutocompleteOrigin #origin="matAutocompleteOrigin">
            <input #box class="search-box" type="text" (keyup)="onQuery($event)" (keyup.enter)="viewMore()"
                placeholder="Search" formControlName="query" [matAutocomplete]="auto"
                [matAutocompleteConnectedTo]="origin">
            <a *ngIf="this.query && this.query.length > 0" mat-icon-button (click)="clearQuery()">
                <mat-icon>close</mat-icon>
            </a>
        </div>
        <mat-autocomplete #auto="matAutocomplete" class="single-autocomplete">
            <ais-instantsearch [config]="config">
                <ais-configure [searchParameters]="searchParameters"></ais-configure>
                <ais-hits class="results">
                    <ng-template let-hits="hits" let-results="results">
                        <div *ngIf="results && results.query && results.query.length > 0">
                            <mat-option class="instant-search-hit" *ngFor="let hit of hits" [value]="hit.title"
                                (click)="goToItem(hit.type, hit.objectID, hit.title)">
                                <img class="thumb" *ngIf="hit.downloadURL200; else noSearchImage"
                                    [src]="hit | mainBuildImage: 100" width="50px" height="50px">
                                <ais-highlight class="title mat-body-strong" attribute="title" [hit]="hit">
                                </ais-highlight>
                            </mat-option>
                        </div>
                    </ng-template>
                </ais-hits>
            </ais-instantsearch>
            <button mat-stroked-button class="more-button" (click)="viewMore()">More results</button>
        </mat-autocomplete>
    </form>
</div>

<ng-template #noSearchImage>
    <img class="thumb" src="../../../assets/img/FPO-Image.png" width="50px" height="50px">
</ng-template>