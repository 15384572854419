export class Comment {
  id?: string;

  displayName: string;

  message: string;

  stepID: string;

  buildID: string;

  uid: string;

  creationTime?: any;

  lastUpdatedTime?: any;

  profileURL?: string;

  profileURL100?: string;

  profileURL200?: string;

  profileURL400?: string;

  edited?: boolean;
}
