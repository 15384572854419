import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchService {
  showSearch = false;

  constructor() {}

  setShowSearch(val: boolean) {
    this.showSearch = val;
    // console.log(this.showSearch);
  }

  getShowSearch(): Observable<boolean> {
    return of(this.showSearch);
  }
}
