import { Component, TemplateRef, ViewContainerRef } from '@angular/core';
import { Router, RouterOutlet, ActivatedRoute } from '@angular/router';
import algoliasearch from 'algoliasearch/lite';
import { Observable } from 'rxjs';
import { SearchOverlayService } from '../../core/services/search-overlay.service';
import { SignupModalComponent } from '../../account/signup-modal/signup-modal.component';
// import { SearchService } from '../../core/services/search.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/auth.service';
// import { slideInAnimation } from '../../router.animations';

const searchClient = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  // animations: [slideInAnimation],
})
export class AppLayoutComponent {
  query = '';

  showFooter = true;

  constructor(public auth: AuthService, private router: Router, private searchOverlay: SearchOverlayService, private route: ActivatedRoute) {
    // console.log(this.route.snapshot);
    // if (route.snapshot.firstChild.data['footer'] === false) {
    if (route.snapshot.firstChild.data.footer === false) {
      this.showFooter = false;
    }
    // this.showFooter = route.snapshot.firstChild.data['footer']? true : false;
  }

  config = {
    indexName: environment.algolia.allindex,
    searchClient,
  };

  get searchParameters() {
    return {
      query: this.query,
      filters: `isPrivate:false`,
      hitsPerPage: 10,
      distinct: true,
    };
  }

  prepareRoute(outlet: RouterOutlet) {
    // Changing the activatedRouteData.state triggers the animation
    // return outlet.activatedRouteData.state;
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/']);
  }

  // canDeactivate(): Promise<boolean> | boolean {
  //   return true;
  // }

  hideSearch(): void {
    // this.search.setShowSearch(false);
  }

  toggleSearch(): void {
    this.searchOverlay.open();
    // this.search.setShowSearch(!this.search.showSearch);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    // if (this.search.getShowSearch()) {
    //   console.log('caught');
    //   return false;
    // }
    return true;
  }
}
