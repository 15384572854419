import { Component, Inject, forwardRef, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { TypedBaseWidget, NgAisInstantSearch, NgAisIndex } from 'angular-instantsearch';

import connectSearchBox, {
  SearchBoxWidgetDescription,
  SearchBoxConnectorParams
} from 'instantsearch.js/es/connectors/search-box/connectSearchBox';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent extends TypedBaseWidget<SearchBoxWidgetDescription, SearchBoxConnectorParams> {
  public state: SearchBoxWidgetDescription['renderState']; // Rendering options

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
    private router: Router
  ) {
    super('SearchBoxComponent');
  }

  ngOnInit() {
    this.createWidget(connectSearchBox, {
      // instance options
      // placeholder: 'string',

    });
    super.ngOnInit();
    // console.log(this.state)
  }

  search(query: string) {
    // if (this.currentQuery == query) {
    //   this.clearQuery();
    // } else {
    this.router.navigate(['/results'], { queryParams: { search_query: query } });
    // }
  }
}
