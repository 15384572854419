<h1>Share</h1>
<mat-grid-list cols="2" rowHeight="80px">
    <mat-grid-tile role="listitem">
        <a mat-fab color="white" (click)="shareTwitter()">
            <img class="logo" src="../../../assets/auth-providers/twitter.svg" alt="Twitter logo">
            <!-- <span class="label">Twitter</span> -->
        </a>
    </mat-grid-tile>
    <mat-grid-tile role="listitem">
        <a mat-fab color="white" (click)="shareFacebook()">
            <img class="logo" src="../../../assets/auth-providers/facebook.svg" alt="Facebook logo">
            <!-- <span class="label">Facebook</span> -->
        </a>
    </mat-grid-tile>
    <mat-grid-tile role="listitem">
        <a mat-fab color="white" (click)="shareEmail()">
            <mat-icon>email</mat-icon>
            <!-- <img class="logo" src="../../../assets/auth-providers/google.svg" alt="Email icon"> -->
            <!-- <span class="label">Email</span> -->
        </a>
    </mat-grid-tile>
    <mat-grid-tile role="listitem">
        <a mat-fab color="#ff4500" (click)="shareReddit()">
            <img class="logo" src="../../../assets/auth-providers/reddit.svg" alt="Reddit logo">
            <!-- <span class="label">Reddit</span> -->
        </a>
    </mat-grid-tile>
</mat-grid-list>
<mat-list>
    <!-- <mat-list-item>
        <a mat-stroked-button color="primary" (click)="shareEmail()">
            <img class="logo" src="../../../assets/auth-providers/google.svg" alt="Text message icon">
            <span class="label">Text message</span>
        </a>
    </mat-list-item> -->
    <mat-list-item role="listitem">
        <a mat-stroked-button color="primary" (click)="copyLinkToClipboard()">
            <mat-icon>content_copy</mat-icon>
            <!-- <img class="logo" src="../../../assets/auth-providers/google.svg" alt="Copy to clipboard icon"> -->
            <span class="label">Copy link</span>
        </a>
    </mat-list-item>
</mat-list>