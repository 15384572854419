<div class="instantSearchHit" *ngIf="result && !breakpointService.isMobile"
    (click)="goToItem(result.type, result.objectID, result.title)">
    <img class="thumb" *ngIf="result.downloadURL400 || result.stepImageURL400; else noSearchImage"
        [src]="result | mainBuildImage: 400">
    <div class="text">
        <p class="title mat-body-strong">{{ result.title }}
        </p>
        <p class="builder mat-caption">{{ result.displayName }} 
            <ng-container *ngIf="result.lastUpdatedTime"> &#183;
            {{ result.lastUpdatedTime._seconds | secondsToRelTime }}</ng-container> 
        </p>
        <!-- <quill-view id="quill-view" class="description" [content]="result.description" format="object" [appTrimQuill]
            [style]="{padding: '0px'}">
        </quill-view> -->
        <div [ngSwitch]="result.type">
            <p class="type" *ngSwitchCase="'build'">Build thread</p>
            <p class="type" *ngSwitchCase="'step'">Step</p>
        </div>
    </div>
</div>

<div class="instantSearchHit mobile" *ngIf="result && breakpointService.isMobile"
    (click)="goToItem(result.type, result.objectID, result.title)">
    <img class="thumb" *ngIf="result.downloadURL400 || result.stepImageURL400; else noSearchImage"
        [src]="result | mainBuildImage: 400">
    <div class="text">
        <p class="title mat-body-strong threeLine">{{ result.title }}</p>
        <p class="builder mat-caption">{{ result.displayName }}   
            <ng-container *ngIf="result.lastUpdatedTime"> &#183;
                {{ result.lastUpdatedTime._seconds | secondsToRelTime }}
            </ng-container> }
        </p>
        <div [ngSwitch]="result.type">
            <p class="type" *ngSwitchCase="'build'">Build thread</p>
            <p class="type" *ngSwitchCase="'step'">Step</p>
        </div>
    </div>
</div>

<ng-template #noSearchImage>
    <img class="thumb" src="../../../assets/img/FPO-Image.png" width="90px" height="90px">
</ng-template>