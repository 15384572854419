export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyDPXSWaA1pbSz9iG-CJx3swX2vvLiptPqc',
    // authDomain: 'firestore-11749.firebaseapp.com',
    authDomain: 'www.buildstring.com',
    databaseURL: 'https://firestore-11749.firebaseio.com',
    projectId: 'firestore-11749',
    storageBucket: 'firestore-11749.appspot.com',
    messagingSenderId: '826499700923',
    appId: '1:826499700923:web:f60837c8ccbf467071719e',
    measurementId: 'G-01Z17ZXQLV',
  },
  algolia: {
    appId: 'GR16ST539I',
    apiKey: 'e634985c3f0ca59e1832e7091034acd0',
    buildindex: 'prod_BUILDS',
    stepindex: 'prod_STEPS',
    allindex: 'prod_ALL',
  },
};
