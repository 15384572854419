export class GallImage {
  src: string;

  msrc: string;

  id: number;

  h: number;

  w: number;

  size: number;

  constructor() {}
}
