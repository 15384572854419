/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs/Observable';
import { map, switchMap, scan, take } from 'rxjs/operators';
import { combineLatest, of, BehaviorSubject } from 'rxjs';
import firebase from 'firebase/compat/app';
import 'firebase/functions';
import { Image } from '../models/image';
import { AuthService } from './auth.service';
import { Comment } from '../models/comment';
import { Step } from '../models/step';
import { Build } from '../models/build';
import { Notification, NotificationTypes } from '../models/notification';
import { User } from '../models/user';

const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);

@Injectable({ providedIn: 'root' })
export class BuildService {
  db = firebase.firestore();

  // BEGIN - Properties for infinitly loading steps
  private _done = new BehaviorSubject(false);

  private _loading = new BehaviorSubject(false);

  private _data = new BehaviorSubject([]);

  data: Observable<Step[]>;

  done: Observable<boolean> = this._done.asObservable();

  loading: Observable<boolean> = this._loading.asObservable();
  // END - Properties for infinitely loading steps

  buildsCollection: AngularFirestoreCollection<Build>;

  buildImagesCollection: AngularFirestoreCollection<Image>;

  buildStepsCollection: AngularFirestoreCollection;

  userId: string;

  stepSort = false;

  newBuildId: string;

  newStepId: string;

  public buildRef: AngularFirestoreDocument<Build>;

  public stepRef: DocumentReference;

  joined$: Observable<any>;

  allBuildsCollection: AngularFirestoreCollection<Build>;

  allBuilds: Observable<Build[]>;

  // used on home page. Should probably be renamed to Featured Builds
  private _allBuilds = new BehaviorSubject<Build[]>([]);

  private _allBuildsLoading = new BehaviorSubject<boolean>(true);

  private _newestBuilds = new BehaviorSubject<Build[]>([]);

  private _newestBuildsLoading = new BehaviorSubject<boolean>(true);

  private _updatedBuilds = new BehaviorSubject<Build[]>([]);

  private _updatedBuildsLoading = new BehaviorSubject<boolean>(true);

  private _userBuilds = new BehaviorSubject<Build[]>([]);

  // used on home page. Should probably be renamed to Featured Builds
  readonly allBuilds$ = this._allBuilds.asObservable();

  readonly allBuildsLoading$ = this._allBuildsLoading.asObservable();

  readonly newestBuilds$ = this._newestBuilds.asObservable();

  readonly newestBuildsLoading$ = this._newestBuildsLoading.asObservable();

  readonly updatedBuilds$ = this._updatedBuilds.asObservable();

  readonly updatedBuildsLoading$ = this._updatedBuildsLoading.asObservable();

  readonly userBuilds$ = this._userBuilds.asObservable();

  private dataStore: {
    allBuilds: Build[];
    allBuildsLoading: boolean;
    newestBuilds: Build[];
    newestBuildsLoading: boolean;
    updatedBuilds: Build[];
    updatedBuildsLoading: boolean;

    userBuilds: Build[];
  } = {
      allBuilds: [],
      allBuildsLoading: true,
      newestBuilds: [],
      newestBuildsLoading: true,
      updatedBuilds: [],
      updatedBuildsLoading: true,

      userBuilds: [],
    };

  constructor(
    //
    private afAuth: AngularFireAuth,
    private auth: AuthService,
    private afs: AngularFirestore,
    private router: Router,
  ) { }

  loadBuilds(numberOfResults: number) {
    this.afs
      .collection<Build>('builds', ref =>
        ref
          // .where('numberOfSteps', '>', 0)
          // .orderBy('title', 'asc')
          .where('featured', '==', true)
          .orderBy('lastUpdatedTime', 'desc')
          .limit(numberOfResults),
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Build;
            const { id } = a.payload.doc;
            // console.log('id: ', id);
            return { id, ...data };
          }),
        ),
      )
      .subscribe(data => {
        this.dataStore.allBuilds = data;
        this._allBuilds.next({ ...this.dataStore }.allBuilds);
        this.dataStore.allBuildsLoading = false;
        this._allBuildsLoading.next({ ...this.dataStore }.allBuildsLoading);
      });
  }

  getFeaturedBuild(): Observable<Build[]> {
    return this.afs
      .collection<Build>('builds', ref =>
        ref
          // .where('numberOfSteps', '>', 0)
          // .orderBy('title', 'asc')
          .where('isPrivate', '==', false)
          .where('featured', '==', true)
          .orderBy('lastUpdatedTime', 'desc')
          .limit(1),
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Build;
            const { id } = a.payload.doc;
            // console.log('id: ', id);
            return { id, ...data };
          }),
        ),
      );
  }

  getRecentBuilds(numberOfResults: number): Observable<Build[]> {
    return this.afs
      .collection('builds', ref => ref.where('isPrivate', '==', false).orderBy('creationTime', 'desc').limit(numberOfResults))
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Build;
            const { id } = a.payload.doc;
            return { id, ...data };
          }),
        ),
      );
  }

  loadNewestBuilds(numberOfResults: number) {
    this.afs
      .collection('builds', ref => ref.where('numberOfSteps', '>', 0).orderBy('numberOfSteps', 'asc').orderBy('creationTime', 'desc').limit(numberOfResults))
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Build;
            const { id } = a.payload.doc;
            return { id, ...data };
          }),
        ),
        take(1),
      )
      .subscribe(data => {
        // console.log(data);
        this.dataStore.newestBuilds = data;
        this._newestBuilds.next({ ...this.dataStore }.newestBuilds);
        this.dataStore.newestBuildsLoading = false;
        this._newestBuildsLoading.next({ ...this.dataStore }.newestBuildsLoading);
      });
  }

  loadUpdatedBuilds(numberOfResults: number) {
    this.afs
      .collection('builds', ref => ref.orderBy('lastUpdatedTime', 'desc').limit(numberOfResults))
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Build;
            const { id } = a.payload.doc;
            return { id, ...data };
          }),
        ),
      )
      .subscribe(data => {
        this.dataStore.updatedBuilds = data;
        this._updatedBuilds.next({ ...this.dataStore }.updatedBuilds);
        this.dataStore.updatedBuildsLoading = false;
        this._updatedBuildsLoading.next({ ...this.dataStore }.updatedBuildsLoading);
      });
  }

  async loadUserBuilds() {
    if (!this.afAuth.authState) {
      return;
    }
    const userId = (await this.afAuth.currentUser).uid;
    this.afs
      .collection('builds', ref => ref.where('uid', '==', userId))
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Build;
            const { id } = a.payload.doc;
            return { id, ...data };
          }),
        ),
      )
      .subscribe(data => {
        this.dataStore.userBuilds = data;
        this._userBuilds.next({ ...this.dataStore }.userBuilds);
      });
  }

  getAllBuilds(): Observable<Build[]> {
    // if (!this.afAuth.authState) {
    //   return;
    // }
    this.allBuilds = this.afs
      .collection('builds', ref => ref)
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Build;
            const { id } = a.payload.doc;
            return { id, ...data };
          }),
        ),
      );
    // console.log(this.allBuilds);
    return this.allBuilds;
  }

  async getBuildsforUser(uid: string): Promise<Build[]> {
    if (!this.afAuth.authState) {
      return new Promise((resolve, reject) => {
        reject(new Error('Oops!'));
      });
    }
    const builds: Build[] = [];
    const collectionReference = this.afs.collection('builds');
    const userBuildsQuery = collectionReference.ref.where('uid', '==', uid);
    return new Promise(resolve => {
      userBuildsQuery.get().then(snap => {
        snap.forEach(document => {
          const data = document.data() as Build;
          const build = { id: document.id, ...data } as Build;
          builds.push(build);
        });
        resolve(builds);
      });
    });
  }

  getImages(id: string): Observable<Image[]> {
    this.buildImagesCollection = this.afs.collection<Image>(`files/`, ref => ref.where('parentId', '==', id).orderBy('position'));
    return this.buildImagesCollection.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data();

          return { id: a.payload.doc.id, ...data };
        }),
      ),
    );
  }

  // returns true if a build has steps, false if not
  hasSteps(buildId: string): Promise<boolean> {
    return new Promise(resolve => {
      const collectionReference = this.afs.collection('steps');
      const q1 = collectionReference.ref.where('buildId', '==', buildId);
      q1.get().then(querySnapshot => {
        resolve(!querySnapshot.empty);
      });
    });
  }

  // returns true if user has builds, false if not
  hasBuilds(userId: string): Promise<boolean> {
    return new Promise(resolve => {
      const collectionReference = this.afs.collection('builds');
      const q1 = collectionReference.ref.where('uid', '==', userId);
      q1.get().then(querySnapshot => {
        resolve(!querySnapshot.empty);
      });
    });
  }

  // returns number of builds a user has
  numberOfUserBuilds(userId: string): Promise<number> {
    return new Promise(resolve => {
      const collectionReference = this.afs.collection('builds');
      const q1 = collectionReference.ref.where('uid', '==', userId);
      q1.get().then(querySnapshot => {
        resolve(querySnapshot.size);
      });
    });
  }

  getBuildSteps(buildId: string, asc?: boolean): Observable<Step[]> {
    // if (!this.afAuth.authState) {
    //   return of({});
    // }
    let stepCollection: AngularFirestoreCollection<Step>;
    if (asc) {
      stepCollection = this.afs.collection<Step>('steps', ref => ref.where('buildId', '==', buildId).orderBy('creationTime', 'asc'));
    } else {
      stepCollection = this.afs.collection<Step>('steps', ref => ref.where('buildId', '==', buildId).orderBy('creationTime', 'desc'));
    }

    return stepCollection.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as Step;
          const { id } = a.payload.doc;
          return { id, ...data };
        }),
      ),
    );
  }

  private mapAndUpdate(col: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) {
    const values = [];

    // Map snapshot with doc ref (needed for cursor)
    col.forEach(document => {
      const { id } = document;
      const data = document.data();
      values.push({ ...data, document, id });
    });

    // If prepending, reverse the batch order
    // values = this.query.prepend ? values.reverse() : values;

    // update source with new values, done loading
    this._data.next(values);
    // console.log(`values ${values}`);
    this._loading.next(false);
    // no more values, mark done
    if (!values.length) {
      this._done.next(true);
    }
  }

  getRecentSteps() {
    this.clearSteps();
    this.afs.firestore
      .collection('steps')
      .where('isPrivate', '==', false)
      // .orderBy('isPrivate')
      .orderBy('creationTime', 'desc')
      .limit(10)
      .get()
      .then(res => this.mapAndUpdate(res));

    this.data = this._data.asObservable().pipe(
      scan((acc, val) => {
        // console.log('acc: ', acc);
        // console.log('val: ', val);
        return acc.concat(val);
      }),
    );
  }

  getMoreRecentSteps() {
    if (this._done.value || this._loading.value) {
      return;
    }

    this._loading.next(true);

    const cursor = this.getCursor();

    this.afs.firestore
      .collection('steps')
      .where('isPrivate', '==', false)
      // .orderBy('isPrivate')
      .orderBy('creationTime', 'desc')
      .limit(10)
      .startAfter(cursor)
      .get()
      .then(res => this.mapAndUpdate(res));

    // this.data = this._data.asObservable().pipe(
    //   scan((acc, val) => {
    //     return acc.concat(val);
    //   }),
    // );
  }

  getInitialBuildSteps(buildId: string, batch: number, asc: boolean) {
    this.clearSteps();
    this.afs.firestore
      .collection('steps')
      .where('buildId', '==', buildId)
      .orderBy('creationTime', asc ? 'asc' : 'desc')
      .limit(batch)
      .get()
      .then(res => this.mapAndUpdate(res));

    // Create the observable array for consumption in components
    this.data = this._data.asObservable().pipe(
      scan((acc, val) => {
        // console.log('acc: ', acc);
        // console.log('val: ', val);
        return acc.concat(val);
      }),
    );
  }

  getMoreBuildSteps(buildId: string, batch: number, asc: boolean) {
    if (this._done.value || this._loading.value) {
      return;
    }
    const cursor = this.getCursor();

    // loading
    this._loading.next(true);

    this.afs.firestore
      .collection('steps')
      .where('buildId', '==', buildId)
      .orderBy('creationTime', asc ? 'asc' : 'desc')
      .limit(batch)
      .startAfter(cursor)
      .get()
      .then(res => this.mapAndUpdate(res));
  }

  clearSteps(): Promise<void> {
    this._data.next([]);
    this._done.next(false);
    this.data = of([]);
    return Promise.resolve();
  }

  // Determines the doc snapshot to paginate query
  private getCursor() {
    const current = this._data.value;
    if (current.length) {
      return current[current.length - 1].document;
    }
    return null;
  }

  getBuildsforUser2(id: string): Observable<any> {
    this.joined$ = this.afs
      .collection<Build>('builds/', ref => ref.where('uid', '==', id))
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data();
            return { id: a.payload.doc.id, ...data };
          }),
        ),
        switchMap(builds => {
          // create an array of all the imageIds from the build
          // If there isn't an image assigned to the build, "default" will be used
          const imageIds = builds.map(bp => (bp.imageId === null || bp.imageId === undefined ? 'default' : bp.imageId));

          // combine the document with that ID and the builds into 1 array
          return combineLatest(
            of(builds),
            combineLatest(
              imageIds.map(imageId =>
                this.afs
                  .collection('files')
                  .doc<Image>(imageId)
                  .valueChanges()
                  .pipe(map(actions => ({ id: imageId, ...actions }))),
              ),
            ),
          );
        }),
        map(([builds, imageIds]) =>
          builds.map(build => ({
            ...build,
            image: imageIds.find(i => i.id === build.imageId),
          })),
        ),
      );

    return this.joined$;
  }

  getBuildByLowercaseId(buildId: string): AngularFirestoreDocument<Build> {
    // this.db.collection()
    // Create a query against the collection.
    // let lowerId: string | Error;
    let buildDoc: AngularFirestoreDocument<Build>;

    const docId = this.db
      .collection('builds')
      .where('idLowercase', '==', buildId.toLowerCase())
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(document => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(document.id, ' => ', document.data());
          return document.id;
        });
      })
      .catch(error => {
        // console.log('Error getting documents: ', error);
      });

    return this.afs.doc<Build>(`builds/${docId}`);
    // return buildDoc;
  }

  getBuildIDByLowercaseId(buildId: string): Promise<string> {
    return this.db
      .collection('builds')
      .where('idLowercase', '==', buildId.toLowerCase())
      .get()
      .then(querySnapshot => querySnapshot.docs[0].id)
      .catch(error => {
        throw new Error(error);
        // return Promise.reject(new Error(error));
      });
  }

  getStepIDByLowercaseId(stepId: string): Promise<string> {
    return this.db
      .collection('steps')
      .where('idLowercase', '==', stepId.toLowerCase())
      .get()
      .then(querySnapshot => querySnapshot.docs[0].id)
      .catch(error => {
        // console.log('Error getting documents: ', error);
        return error;
      });
  }

  getBuild(id: string): AngularFirestoreDocument<Build> {
    return this.afs.doc<Build>(`builds/${id}`);
  }

  getStepDoc(id: string): AngularFirestoreDocument<Step> {
    return this.afs.doc<Step>(`steps/${id}`);
  }

  async getBuildTitle(buildId: string): Promise<string> {



    return new Promise((resolve, reject) => {
      this.afs
        .doc<Build>(`builds/${buildId}`)
        .ref.get()
        .then(document => {
          const data = document.data();
          resolve(data.title)
        })
    });
  }

  getBuildOwner(id: string): Observable<User> {
    const userDoc = this.afs.doc<User>(`users/${id}`);
    return userDoc.snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data();
        return { ...data };
      }),
    );
  }

  getStep(id: string): Observable<Step> {
    const stepDoc = this.afs.doc<Step>(`steps/${id}`);
    return stepDoc.snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data();
        return { id: a.payload.id, ...data };
      }),
    );
  }

  // newBuild(): AngularFirestoreDocument<Build> {
  newBuild(): string {
    this.newBuildId = this.afs.createId();
    this.buildRef = this.afs.collection<Build>('builds').doc(this.newBuildId);
    return this.newBuildId;
  }

  // newStep(): AngularFirestoreDocument<Step> {
  newStep(): string {
    this.newStepId = this.afs.createId();
    this.stepRef = this.afs.collection<any>('steps').doc(this.newStepId).ref;
    return this.newStepId;
  }

  // newComment(): string {
  //   this.newCommentId = this.afs.createId();
  //   this.commentRef = this.afs.collection<any>('steps').doc(this.newStepId).ref;
  //   return this.newCommentId;
  // }

  // Removed images from build. Here is the old version
  // createBuild(newBuild: Build, images: Image[]): Promise<boolean> {
  async createBuild(build: Build, id: string, image: Image): Promise<boolean> {
    const user = await this.auth.getUser();

    // console.log(user);
    const newBuild = new Build();
    newBuild.id = id;
    newBuild.idLowercase = id.toLowerCase();
    newBuild.uid = (await this.afAuth.currentUser).uid;
    newBuild.title = build.title;
    newBuild.isPrivate = build.isPrivate;
    newBuild.displayName = (await this.afAuth.currentUser).displayName;
    newBuild.profileURL = user.profileURL ? user.profileURL : null;
    newBuild.profileURL100 = user.profileURL100 ? user.profileURL100 : null;
    newBuild.profileURL200 = user.profileURL200 ? user.profileURL200 : null;
    newBuild.profileURL400 = user.profileURL400 ? user.profileURL400 : null;
    newBuild.isComplete = false;

    // If the build has a description, we need to get the part from Quill to store in firebase
    if (build.description) {
      newBuild.description = build.description.ops;
    }
    // console.log('useStepImage is actually: ', typeof build.useStepImage);
    // Depending on whether or not to use the step image of the main image
    if (build.useStepImage === false) {
      // console.log('useStepImage was seen as false');
      newBuild.useStepImage = false;
      newBuild.imageId = image.id;
      newBuild.downloadURL200 = image.downloadURL200;
      newBuild.downloadURL400 = image.downloadURL400;
      newBuild.downloadURL800 = image.downloadURL800;
    } else {
      // console.log('useStepImage was seen as true');
      // Since this is a new build there won't be an image from the first step....yet
      // It will get updated once they add their first step with an image.
      newBuild.useStepImage = true;
      newBuild.imageId = null;
      newBuild.downloadURL200 = 'assets/img/FPO-Image.png';
      newBuild.downloadURL400 = 'assets/img/FPO-Image.png';
      newBuild.downloadURL800 = 'assets/img/FPO-Image.png';
    }

    // console.log(newBuild);

    this.buildRef.set({
      creationTime: firebase.firestore.FieldValue.serverTimestamp(),
      lastUpdatedTime: firebase.firestore.FieldValue.serverTimestamp(),
      ...newBuild,
    });
    return new Promise(resolve => {
      resolve(true);
    });
  }

  async createStep(step: Step, images: Image[], id: string, isPrivate: boolean): Promise<void> {
    // const buildDoc: AngularFirestoreDocument<Build> = this.afs.doc(`builds/${step.buildId}`);
    const buildDoc = this.afs.doc<Build>(`builds/${step.buildId}`).ref;

    const updatedBuild: Build = {};
    const newStep = new Step();

    newStep.title = step.title;
    // this gets inherited from the build setting
    newStep.isPrivate = isPrivate;
    if (step.description) {
      newStep.description = step.description.ops;
    }
    newStep.idLowercase = id.toLowerCase();
    newStep.uid = (await this.afAuth.currentUser).uid;
    newStep.buildId = step.buildId;
    newStep.displayName = (await this.afAuth.currentUser).displayName;
    if (images.length > 0) {
      this.updateImages(images);
      newStep.imageId = images[0].id;
      newStep.downloadURL200 = images[0].downloadURL200;
      newStep.downloadURL400 = images[0].downloadURL400;
      newStep.downloadURL800 = images[0].downloadURL800;

      // Updating the build to use this image as well.
      updatedBuild.stepImageId = images[0].id;
      updatedBuild.stepImageURL200 = images[0].downloadURL200;
      updatedBuild.stepImageURL400 = images[0].downloadURL400;
      updatedBuild.stepImageURL800 = images[0].downloadURL800;
      updatedBuild.latestStepTitle = step.title;
    } else {
      newStep.imageId = null;
      newStep.downloadURL200 = null;
      newStep.downloadURL400 = null;
      newStep.downloadURL800 = null;
    }

    const batch = firebase.firestore().batch();
    batch.set(this.stepRef, {
      lastUpdatedTime: firebase.firestore.FieldValue.serverTimestamp(),
      creationTime: firebase.firestore.FieldValue.serverTimestamp(),
      ...newStep,
    });

    batch.update(buildDoc, {
      lastUpdatedTime: firebase.firestore.FieldValue.serverTimestamp(),
      numberOfSteps: increment,
      ...updatedBuild,
    });

    batch.commit().then(() => {
      // console.log(step);
      return Promise.resolve();
    });
  }

  async createComment(comment: Comment, stepId: string, buildId: string, step: Step): Promise<boolean> {
    // console.log('comment: ', comment);
    // console.log('stepId: ', stepId);
    // console.log('buildId: ', buildId);
    // console.log('step: ', step);

    const user = await this.auth.getUser();

    // console.log(user);

    const newCommentId = this.afs.createId();

    const commentDoc = this.afs.collection('comments').doc(newCommentId).ref;

    const newComment = new Comment();

    newComment.displayName = (await this.afAuth.currentUser).displayName;
    newComment.message = comment.message;
    newComment.stepID = stepId;
    newComment.buildID = buildId;
    newComment.uid = (await this.afAuth.currentUser).uid;
    newComment.profileURL = user.profileURL ? user.profileURL : null;
    newComment.profileURL100 = user.profileURL100 ? user.profileURL100 : null;
    newComment.profileURL200 = user.profileURL200 ? user.profileURL200 : null;
    newComment.profileURL400 = user.profileURL400 ? user.profileURL400 : null;

    // console.log(newComment);

    const batch = firebase.firestore().batch();

    batch.set(commentDoc, {
      lastUpdatedTime: firebase.firestore.FieldValue.serverTimestamp(),
      creationTime: firebase.firestore.FieldValue.serverTimestamp(),
      ...newComment,
    });

    batch.commit().then(() => {
      // console.log('comment created');
      // this.router.navigate(['build/my-builds']);
    });

    return new Promise(resolve => {
      resolve(true);
    });
  }

  deleteComment(commentId: string) {
    this.afs
      .collection('comments')
      .doc(commentId)
      .delete()
      .catch(error => {
        // console.error('Error removing document: ', error);
      });
  }

  async createCommentNotification(commenter: User, step: Step) {
    const newNotification = new Notification();
    // await ;
    await this.auth.getUserById(step.uid).then(user => {
      newNotification.toUser = user;
    });
    newNotification.fromUser = commenter;
    newNotification.type = NotificationTypes.Own;
    newNotification.targetURL = 'http://localhost:4200/';
    newNotification.read = false;

    return newNotification;
  }

  createNotificationDocRef() {
    const newNotificationID = this.afs.createId();
    return this.afs.collection('notifications').doc(newNotificationID).ref;
  }

  async updateBuild(build: Build, id: string, mainImageChanged: boolean): Promise<boolean> {
    // console.log(build);
    // console.log('Main image setting changed:', mainImageChanged);
    const buildDoc: AngularFirestoreDocument<Build> = this.afs.doc(`builds/${id}`);
    const updatedBuild = build;
    if (build.description !== null) {
      if (build.description.ops === undefined) {
        updatedBuild.description = build.description;
      } else {
        updatedBuild.description = build.description.ops;
      }
    }
    updatedBuild.title = build.title;
    updatedBuild.useStepImage = build.useStepImage;

    // isPrivate gets propogated to all steps via a cloud function
    updatedBuild.isPrivate = build.isPrivate;

    if (mainImageChanged) {
      if (build.useStepImage) {
        const mostRecentStep = await this.afs.firestore
          .collection('steps')
          .where('buildId', '==', id)
          .orderBy('creationTime', 'desc')
          .limit(1)
          .get()
          .then(snapshot => {
            const firstStep = snapshot.docs[0].data();
            // console.log('firstStep:', firstStep);
            return firstStep as Step;
          });
      }
    }

    buildDoc.update({
      ...updatedBuild,
    });

    return true;
  }

  isLatestStep(stepId: string, buildId: string): Promise<boolean> {
    let isLatest: boolean;
    let steps: Step[];
    const mostRecentStep = this.afs.firestore
      .collection('steps')
      .where('buildId', '==', buildId)
      .orderBy('creationTime', 'desc')
      .limit(1)
      .get()
      .then(querySnapshot => {
        const firstDoc = querySnapshot.docs;
        // console.log(firstDoc[0].id);
        if (firstDoc[0].id === stepId) {
          return true;
        }
        return false;
      });

    return Promise.resolve(mostRecentStep);
  }

  async updateStep(step: Step, id: string, images: Image[]): Promise<void> {
    const stepDoc: AngularFirestoreDocument<Step> = this.afs.doc(`steps/${id}`);
    const buildDoc: AngularFirestoreDocument<Build> = this.afs.doc(`builds/${step.buildId}`);

    const updatedStep = new Step();
    if (images.length > 0) {
      updatedStep.imageId = images[0].id;
      updatedStep.downloadURL200 = images[0].downloadURL200;
      updatedStep.downloadURL400 = images[0].downloadURL400;
      updatedStep.downloadURL800 = images[0].downloadURL800;
    } else {
      updatedStep.imageId = null;
      updatedStep.downloadURL200 = null;
      updatedStep.downloadURL400 = null;
      updatedStep.downloadURL800 = null;
    }
    // console.log(step);
    updatedStep.title = step.title;
    if (step.description) {
      updatedStep.description = step.description;
    }

    const updatedBuild: Build = {};

    const isLatest = await this.isLatestStep(step.id, step.buildId);

    if (isLatest) {
      // console.log('it is the latest');
      if (images.length > 0) {
        updatedBuild.stepImageId = images[0].id;
        updatedBuild.stepImageURL200 = images[0].downloadURL200;
        updatedBuild.stepImageURL400 = images[0].downloadURL400;
        updatedBuild.stepImageURL800 = images[0].downloadURL800;
        updatedBuild.latestStepTitle = step.title;
      }
    } else {
      // console.log('it is not the latest');
    }

    stepDoc
      .update({
        lastUpdatedTime: firebase.firestore.FieldValue.serverTimestamp(),
        ...updatedStep,
      })
      .then(() =>
        buildDoc.update({
          ...updatedBuild,
        }),
      );
  }

  updateImages(images: Image[]) {
    const batch = this.afs.firestore.batch();
    images.forEach(image => {
      const docRef = this.afs.collection('files').doc(image.id).ref;
      batch.update(docRef, { position: image.position });
    });
    batch.commit().then(() => {
      // this.location.back();
      this.router.navigate(['build/my-builds']);
    });
  }

  updatePrimaryBuildImage(image: Image) {
    // console.log('Update Build Image to: ', image);
    const buildDoc: AngularFirestoreDocument<Build> = this.afs.doc(`builds/${image.buildId}`);
    const data = {
      useStepImage: false,
      imageId: image.id,
      downloadURL200: image.downloadURL200,
      downloadURL400: image.downloadURL400,
      downloadURL800: image.downloadURL800,
    };
    buildDoc.set(data, { merge: true });
  }

  deleteStep(step: Step) {
    const stepReference = this.afs.collection('steps').doc(step.id).ref;
    const buildfReference = this.afs.collection('builds').doc(step.buildId).ref;

    const batch = firebase.firestore().batch();
    batch.delete(stepReference);
    batch.update(buildfReference, {
      numberOfSteps: decrement,
    });

    batch.commit();
  }

  deleteBuild(id: string) {
    this.afs
      .collection('builds')
      .doc(id)
      .delete()
      .then(() => {
        // console.log('Build successfully deleted!');
      })
      .catch(error => {
        console.error('Error removing document: ', error);
      });
  }

  // Deletes the image document.
  // A cloud function deletes the files from storage when
  // it sees a file document has been deleted.
  deleteImage(id: string): boolean {
    this.afs
      .collection('files')
      .doc(id)
      .delete()
      .then(() => {
        // console.log('file for image successfully deleted!');
        return true;
      })
      .catch(error => {
        throw new Error(error);
      });
    return true;
  }

  getComments(stepId: string): Observable<Comment[]> {
    const commentCollection: AngularFirestoreCollection<Comment> = this.afs
      // .collection('steps')
      // .doc(stepId)
      .collection('comments', ref => ref.where('stepID', '==', stepId).orderBy('creationTime', 'desc'));

    return commentCollection.snapshotChanges().pipe(
      map(actions =>
        actions.map(a => {
          const data = a.payload.doc.data() as Comment;
          // const data = a.payload.doc.data();
          // console.log('data: ', data);
          const { id } = a.payload.doc;
          return { id, ...data };
        }),
      ),
    );
  }
}
