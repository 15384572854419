import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery.component';
import { SwiperModule } from 'swiper/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';




@NgModule({
  declarations: [GalleryComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SwiperModule,
    MatButtonModule,
  ],
  exports: [
    GalleryComponent
  ]
})
export class GalleryModule { }
