import { Component, OnInit, Input } from '@angular/core';
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper/core';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { GallImage } from '../core/models/gallImage';
import { BreakpointService } from '../core/services/breakpoint.service';

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y]);

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
  @Input() id: string;

  @Input() images: GallImage[];

  length: string;

  displayedImages: GallImage[];

  moreImages = false;

  isMobile: boolean;



  constructor(
    public breakpointService: BreakpointService,
    public breakpointObserver: BreakpointObserver,
  ) { }

  ngOnInit() {
    console.log(this.images);

    if (this.images.length > 5) {
      this.length = 'many';
      this.displayedImages = this.images.slice(0, 5);
      this.moreImages = true;
    } else {
      this.length = this.images.length.toString();
      this.displayedImages = this.images;
    }

    this.breakpointObserver.observe(['(min-width: 600px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
  }

  openPhotoSwipe(e, index, clickedGallery) {
    // eslint-disable-next-line no-param-reassign
    e = e || window.event;
    // eslint-disable-next-line no-unused-expressions
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);
    let pswpElement: HTMLElement;
    let options: any;

    if (!this.isMobile) {
      const selector = '.'.concat(clickedGallery, ' > figure');
      pswpElement = document.querySelectorAll('.pswp')[0] as HTMLElement;
      // define options (if needed)

      options = {
        index, // start at first slide
        shareEl: false,
        showHideOpacity: true,
        // getThumbBoundsFn(i) {
        //   const thumbnails = document.querySelectorAll(selector);
        //   // find thumbnail element
        //   const thumbnail = thumbnails[i];
        //   // console.log(thumbnail);
        //   // get window scroll Y
        //   const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        //   // optionally get horizontal scroll
        //   // get position of element relative to viewport
        //   const rect = thumbnail.getBoundingClientRect();
        //   // w = width
        //   return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
        //   // Good guide on how to get element coordinates:
        //   // http://javascript.info/tutorial/coordinates}
        // },
      };
    } else {
      // console.log(index);
      // console.log(clickedGallery);
      // const selector = '.'.concat(clickedGallery, ' > figure');
      const selector = '.swiper.'.concat(clickedGallery);
      // selector.concat('.', clickedGallery);
      // console.log(selector);
      pswpElement = document.querySelectorAll('.pswp')[0] as HTMLElement;
      // define options (if needed)

      options = {
        index, // start at first slide
        shareEl: false,
        showHideOpacity: true,
        getThumbBoundsFn(i) {
          const thumbnails = document.querySelectorAll(selector);
          // console.log(thumbnails);
          // console.log('i ', i);
          // find thumbnail element
          // const thumbnail = thumbnails[i];
          const thumbnail = thumbnails[0];
          // console.log(thumbnail);
          // get window scroll Y
          const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
          // console.log(window.pageYOffset);
          // console.log(document.documentElement.scrollTop);

          // optionally get horizontal scroll
          // get position of element relative to viewport
          const rect = thumbnail.getBoundingClientRect();
          // w = width
          return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
          // Good guide on how to get element coordinates:
          // http://javascript.info/tutorial/coordinates}
        },
      };
    }

    const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUIDefault, this.images, options);
    gallery.init();
  }

  onSwiper(swiper) {
    // console.log(swiper);
  }

  onSlideChange() {
    // console.log('slide change');
  }
}
