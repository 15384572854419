import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GallImage } from '../../core/models/gallImage';
import { Step } from '../../core/models/step';
import { Comment } from '../../core/models/comment';
import { AuthService } from '../../core/services/auth.service';
import { BreakpointService } from '../../core/services/breakpoint.service';
import { BuildService } from '../../core/services/build.service';
import { User } from '../../core/models/user';
import { cleanTitle } from 'src/app/_helpers/string-hypenator';

@Component({
  selector: 'app-home-step',
  templateUrl: './home-step.component.html',
  styleUrls: ['./home-step.component.scss'],
})
export class HomeStepComponent implements OnInit {
  stepImages: Observable<GallImage[]>;

  comments: Comment[];

  @Input() step: Step;

  @Input() showComments: boolean;

  userId: string;

  buildTitle: string;

  stepOwner: Observable<User>;

  constructor(private buildService: BuildService, private router: Router, public auth: AuthService, public breakpointService: BreakpointService) { }

  async ngOnInit() {
    this.stepOwner = await this.buildService.getBuildOwner(this.step.uid);
    this.buildService.getBuildTitle(this.step.buildId).then(res => {
      this.buildTitle = res;
    });

    this.stepImages = this.getStepImages();
  }

  getStepImages(): Observable<GallImage[]> {
    return this.buildService.getImages(this.step.id).pipe(
      map(images => {
        return images.map((image, index) => {
          const galleryImage = new GallImage();
          console.log(image.downloadURL);

          galleryImage.src = image.downloadURL;
          galleryImage.msrc = image.downloadURL400;
          galleryImage.id = index;
          if (!image.height || !image.width) {
            galleryImage.h = 1000;
            galleryImage.w = 1000;
          } else {
            galleryImage.h = image.height;
            galleryImage.w = image.width;
          }
          return galleryImage;
        });
      }),
    );
  }

  goToStep(stepId: string, stepTitle: string) {
    const cleanStepTitle = cleanTitle(stepTitle)
    this.router.navigate([`/step/${cleanStepTitle}.${stepId}`]);
  }
}
