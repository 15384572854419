<span class="breadcrumb mat-caption"><a [routerLink]="['/']">Home</a>
    >
    Search results
</span>
  <div class="ais-InstantSearch">
    <ais-instantsearch [config]="config">
      <div class="right-panel">
        <app-search-box></app-search-box>
        <!-- <ais-search-box></ais-search-box> -->
        <app-refinement-list attribute="type"></app-refinement-list>
        <ais-hits>
          <ng-template let-hits="hits">
            <ol class="ais-Hits-list">
              <li *ngFor="let hit of hits" class="ais-Hits-item">
                <app-search-result [result] = "hit"></app-search-result>
              </li>
            </ol>
          </ng-template>
        </ais-hits>
        <app-search-pagination></app-search-pagination>
      </div>
    </ais-instantsearch>
  </div>