import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
  // Overrides any console.log command when in production mode
  if (window) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.console.log = () => {};
  }
}

platformBrowserDynamic().bootstrapModule(AppModule);
