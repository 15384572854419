<div *ngIf="this.state" class="type-list filters">
  <button mat-stroked-button class="filter-button" (click)="accordion.open()"><mat-icon>filter_list</mat-icon>Filters</button>
  <mat-accordion multi> 
    <mat-expansion-panel class="expansion-panel" id="filters">
       <h4>Type</h4>
       <mat-divider></mat-divider>
      <div class="filters-list">
        <mat-checkbox *ngFor="let item of this.state.items" color="primary"
          (click)="state.refine(item.value)"  
          [checked]="item.isRefined" >{{ item.label | titlecase  }} ({{item.count}})</mat-checkbox>
      </div>
      <app-search-clear-assisted-navigation [accordion]="this.accordion"></app-search-clear-assisted-navigation>
      </mat-expansion-panel>
  </mat-accordion>
</div>