export class Build {
  id?: string;

  idLowercase?: string;

  title?: string;

  description?: any;

  uid?: string;

  imageId?: string;

  stepImageId?: string;

  displayName?: string;

  downloadURL200?: string;

  downloadURL400?: string;

  downloadURL800?: string;

  stepImageURL200?: string;

  stepImageURL400?: string;

  stepImageURL800?: string;

  profileURL?: string;

  profileURL100?: string;

  profileURL200?: string;

  profileURL400?: string;

  creationTime?: any;

  lastUpdatedTime?: any;

  isComplete?: boolean;

  useStepImage?: boolean;

  latestStepTitle?: string;

  numberOfSteps?: number;

  numberOfComments?: number;

  featured?: boolean;

  isPrivate?: boolean;
}
