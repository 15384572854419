<!-- <div class="buildCard" routerLink="/build/{{build.id}}" ngClass.xs="xs" matTooltip="{{build.description}}"> -->
<!-- Tooltip is causing scrolling issues on mobile. Turning off for now.mat-divider -->
<!-- User story: https://nateclicks.visualstudio.com/BuildString/_workitems/edit/241 -->


<div class="buildCard build-card" routerLink="/build/{{build.title | seoString}}.{{build.id}}" ngClass.xs="xs">
  <div class="image" *ngIf="build.imageId || build.stepImageId; else noImage">
    <!-- <img src="{{buildd.downloadURL400}}" alt="{{build.title}}" srcset="{{build.downloadURL400}} 1x, {{build.downloadURL800}} 2x,
      {{build.downloadURL800}} 3x"> -->
    <img src="{{build | mainBuildImage: 200}}" alt="{{build.title}}" srcset="{{build | mainBuildImage: 400}} 1x, {{build | mainBuildImage: 800}} 2x,
    {{build | mainBuildImage: 800}} 3x">
  </div>
  <img class="avatar" *ngIf="build.profileURL100; else noAvatar" src="{{build.profileURL100}}"
    alt="{{build.displayName}}">
  <div class="info">
    <span class="step-title oneLine mat-body-strong" *ngIf="build.latestStepTitle">{{build.latestStepTitle}}</span>
    <span class="build-title oneLine mat-caption">{{build.title}}<br></span>
    <span class="mat-caption">{{ build.displayName }}
    <ng-container *ngIf="build.lastUpdatedTime"> &#183;
      {{ build.lastUpdatedTime.seconds | secondsToRelTime }}
    </ng-container></span> 
    <!-- <span class="time mat-caption">{{ build.lastUpdatedTime.seconds | secondsToRelTime }}</span> -->
    <!-- <div class="time mat-caption oneLine" *ngIf="build.lastUpdatedTime">
    {{ build.lastUpdatedTime.seconds | secondsToRelTime }}</div> -->
  </div>
</div>

<ng-template #noImage>
  <div class="image"><img src="assets/img/FPO-Image.png"></div>
</ng-template>
<ng-template #noAvatar>
  <div class="avatar" style="width: 32px; height: 32px"></div>
</ng-template>