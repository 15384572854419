import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';

import { TextFieldModule } from '@angular/cdk/text-field';

import { NgAisModule, NgAisInstantSearch } from 'angular-instantsearch';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatGridListModule } from '@angular/material/grid-list';
import { NewLineToLineBreakPipe } from './pipes/newLineToLineBreak.pipe';
import { BuildPickerSheetComponent } from './build-picker-sheet/build-picker-sheet.component';
import { FollowButtonComponent } from './follow-button/follow-button.component';
import { TrimTextDirective } from './directives/trim-text.directive';
import { TrimQuillDirective } from './directives/trim-quill.directive';
import { UploadTaskComponent } from '../upload-task/upload-task.component';

import { SecondsToRelTimePipe } from './pipes/secondsToRelTime.pipe';
import { MainBuildImagePipe } from './pipes/main-build-image.pipe';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { FileUploadButtonComponent } from './file-upload-button/file-upload-button.component';
import { SingleImageUploaderComponent } from './single-image-uploader/single-image-uploader.component';
import { UploaderComponent } from '../uploader/uploader.component';
import { SearchComponent } from './search/search.component';
import { ShareButtonComponent } from './share-button/share-button.component';
import { ShareModalComponent } from './share-modal/share-modal.component';
import { SiteFooterComponent } from '../_layout/site-footer/site-footer.component';
import { MatCardModule } from '@angular/material/card';
import { SearchAssistedNavigationComponent } from './search-assisted-navigation/search-assisted-navigation.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { SearchClearAssistedNavigationComponent } from './search-clear-assisted-navigation/search-clear-assisted-navigation.component';
import { RelatedBuildsComponent } from './related-builds/related-builds.component';
import { BuildCardComponent } from '../builds/shared/build-card/build-card.component';
import { SEOStringPipe } from './pipes/seo-string.pipe';
import { GalleryModule } from '../gallery/gallery.module';


@NgModule({
  declarations: [
    NewLineToLineBreakPipe,
    BuildPickerSheetComponent,
    FollowButtonComponent,
    TrimQuillDirective,
    TrimTextDirective,
    SingleImageUploaderComponent,
    UploadTaskComponent,
    SecondsToRelTimePipe,
    MainBuildImagePipe,
    SEOStringPipe,
    HomeBannerComponent,
    DeleteConfirmationDialogComponent,
    UploaderComponent,
    FileUploadButtonComponent,
    SearchComponent,
    ShareButtonComponent,
    ShareModalComponent,
    SiteFooterComponent,
    SearchAssistedNavigationComponent,
    SearchClearAssistedNavigationComponent,
    RelatedBuildsComponent,
    BuildCardComponent,
  ],
  imports: [
    ReactiveFormsModule,
    NgAisModule,
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatSnackBarModule,
    MatTooltipModule,
    TextFieldModule,
    RouterModule,
    MatProgressSpinnerModule,
    DragDropModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatCardModule,
    GalleryModule,
  ],
  exports: [
    NewLineToLineBreakPipe,
    BuildPickerSheetComponent,
    FollowButtonComponent,
    TrimTextDirective,
    TrimQuillDirective,
    SingleImageUploaderComponent,
    UploadTaskComponent,
    FlexLayoutModule,
    SecondsToRelTimePipe,
    MainBuildImagePipe,
    SEOStringPipe,
    HomeBannerComponent,
    NgAisModule,
    UploaderComponent,
    SearchComponent,
    ShareButtonComponent,
    SiteFooterComponent,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    SearchAssistedNavigationComponent,
    RelatedBuildsComponent,
    BuildCardComponent,
  ],
  providers: [
    NgAisInstantSearch,
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 500,
        hideDelay: 0,
        touchendHideDelay: 1000,
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
        verticalPosition: 'top',
        horizontalPosition: 'center',
      },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ],
})
export class SharedModule { }
