import { Directive, ElementRef, Input, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appTrimQuill]',
})
export class TrimQuillDirective implements AfterViewInit {
  @Input('appTrimQuill') trim: boolean;

  @Input('moreLink') addMoreLink?: boolean;

  collapsed = true;

  linkAdded = false;

  viewCheckedListening = true;

  showMoreElement: any;

  constructor(
    //
    private _el: ElementRef,
    private _renderer: Renderer2,
  ) {
    // this.renderer.setStyle(this.el.nativeElement, 'background-color', color);
  }

  ngAfterViewInit() {}

  ngAfterViewChecked() {
    if (this.viewCheckedListening) {
      if (this._el.nativeElement.children.length !== undefined && this._el.nativeElement.children.length > 0) {
        // console.log(this._el.nativeElement.children.length);
        const textHeight = this._el.nativeElement.children[0].children[0].scrollHeight;
        // console.log('textHeight: ', textHeight);
        if (this.collapsed && textHeight > 100 && this.linkAdded === false) {
          this._renderer.setStyle(this._el.nativeElement, 'max-height', '100px');
          this.addShowMoreLink();
        } else {
          this.viewCheckedListening = false;
        }
      }
    }
  }

  onClick() {
    // console.log('clicked');
  }

  private getInitialCharacers(text: string, length: number): string {
    return text.slice(0, length);
  }

  private addShowMoreLink(): any {
    this.showMoreElement = this._renderer.createElement('div');
    this._renderer.addClass(this.showMoreElement, 'expand');
    // this._renderer.setProperty(showMoreElement, 'innerText', 'more');

    // if (this.addMoreLink) {
    const showMoreLink = this._renderer.createElement('button');
    this._renderer.addClass(showMoreLink, 'mat-stroked-button');
    this._renderer.setProperty(showMoreLink, 'type', 'button');
    this._renderer.setProperty(showMoreLink, 'color', 'primary');
    this._renderer.setProperty(showMoreLink, 'innerText', 'Expand');
    this._renderer.setStyle(showMoreLink, 'width', '100%');
    this._renderer.listen(showMoreLink, 'click', (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      this.showFullText();
      // console.log('toggle');
    });

    this._renderer.appendChild(this.showMoreElement, showMoreLink);
    // }

    this.linkAdded = true;
    this.viewCheckedListening = false;
    return this._renderer.appendChild(this._el.nativeElement.parentNode, this.showMoreElement);

    // const appendedString = '...<a (click)="clicked()">Show more</a>';
    // return text.concat(appendedString);
  }

  showFullText() {
    this._renderer.removeChild(this._el.nativeElement, this.showMoreElement);
    return this._renderer.removeStyle(this._el.nativeElement, 'max-height');
  }
}
