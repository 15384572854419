import { Component, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss'],
})
export class SignupModalComponent {
  loginForm: FormGroup;

  submitted = false;

  state = '';

  error: any;

  @ViewChild('password') public passwordRef: ElementRef;

  constructor(public auth: AuthService, private router: Router, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<SignupModalComponent>) {
    this.loginForm = this.getForm();

    // NOTE: this hack triggers form validation on iOS autofill
    this.loginForm.valueChanges
      // wait for 500ms to be sure the autofill is finished with the password field
      .pipe(debounceTime(500))
      .subscribe(changes => {
        // manually fetch the value from nativeElement
        const passVal = this.passwordRef.nativeElement.value; // (<HTMLInputElement>document.querySelector('[name="password"]')).value;
        // if the value saved in the form is different from the input, manually update it
        if (passVal !== changes.password) {
          this.loginForm.get('password').setValue(passVal);
        }
      });
    // NOTE: this hack triggers form validation on iOS autofill
  }

  protected getForm(): FormGroup {
    return this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  googleLogin() {
    this.auth
      .googleSignIn()
      .then(result => {
        this.dialogRef.close();
      })
      .catch((err: Error) => {
        this.error = err;
      });
  }

  async onSubmitLogin() {
    if (this.loginForm.valid) {
      // console.log('submitted');
      await this.auth
        .signIn(this.loginForm.value.email, this.loginForm.value.password)
        .then(success => {
          this.dialogRef.close();
        })
        .catch(err => {
          switch (err.code) {
            case 'auth/wrong-password':
            case 'auth/user-not-found':
              this.error = 'Incorrect email or password. Please try again.';
              break;
            default:
              this.error = err;
          }
        });
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }
}
