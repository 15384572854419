/* eslint-disable no-param-reassign */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class SeoService {

  static scriptType = 'application/ld+json';
  static websiteSchema = (url?: string, name?: string) => {
    return {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: url || 'https:\/\/www.buildstring.com',
      name: name || 'BuildString',
    };
  };

  static orgSchema = () => ({
    '@context':
      'https://schema.org',
    '@type':
      'Organization',
    url:
      'https://www.buildstring.com',
    name:
      'BuildString',
    "sameAs": ["https://facebook.com/buildstring",
      "https://instagram.com/buildstring",
      "https://twitter.com/buildstring"],
    contactPoint: {
      '@type':
        'ContactPoint',
      email:
        'info@buildstring.com',
      contactType:
        'Customer service'
    }
  });

  public title = 'BuildString';

  constructor(private meta: Meta, @Inject(DOCUMENT) private _document: Document) { }

  removeStructuredData(): void {
    const els = [];
    ['structured-data', 'structured-data-org'].forEach(c => {
      els.push(...Array.from(this._document.head.getElementsByClassName(c)));
    });
    els.forEach(el => this._document.head.removeChild(el));
  }

  insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
    console.log(schema);

    let script;
    let shouldAppend = false;
    if (this._document.head.getElementsByClassName(className).length) {
      script = this._document.head.getElementsByClassName(className)[0];
    } else {
      script = this._document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    script.type = SeoService.scriptType;
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this._document.head.appendChild(script);
    }
  }

  async generateTags(config?) {
    // default values
    // neither config nor description can be undefined
    if (config && config.description && typeof config.description !== "string") {
      // console.log('description', typeof config.description);
      config.description = await this.mapDescriptionToString(config.description).then(result => result);
    }


    config = {
      title: this.title,
      description: `A community of builders and their build threads. Create you own build thread and share what you're building.`,
      image: 'https://www.buildstring.com/assets/android-chrome-512x512.png',
      ogImage: 'https://www.buildstring.com/assets/android-chrome-512x512.png',
      slug: '',
      ...config,
    };
    // this.meta.updateTag({ name: 'description', content: config.description });
    this.meta.updateTag({ name: 'description', content: config.description });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@BuildString' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({
      name: 'twitter:description',
      content: config.description,
    });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'BuildString' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({
      property: 'og:description',
      content: config.description,
    });
    this.meta.updateTag({ property: 'og:image', content: config.ogImage });
    this.meta.updateTag({
      property: 'og:url',
      content: `https://www.buildstring.com/${config.slug}`,
    });
  }

  async mapDescriptionToString(description): Promise<string> {
    let descriptionString = '';

    description.forEach(item => {
      if (item.insert !== '\n') {
        // using regex to replace any line breaks in the string with a space
        descriptionString += item.insert.replace(/[\r\n]+/g, ' ');
      } else {
        descriptionString += ' ';
      }
    });
    if (descriptionString.length > 160) {
      descriptionString = descriptionString.substr(0, 160);
    }
    return descriptionString;
  }


}
