import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable } from 'rxjs';
import { Feedback } from '../models/feedback';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  // BEGIN - Properties for submitting feedback
  private _done = new BehaviorSubject(false);

  private _loading = new BehaviorSubject(false);

  done: Observable<boolean> = this._done.asObservable();

  loading: Observable<boolean> = this._loading.asObservable();

  // END - Properties for infinitely loading steps
  constructor(
    //
    private afs: AngularFirestore,
    private auth: AuthService,
  ) {}

  async submitFeedback(feedback: Feedback): Promise<boolean> {
    this._loading.next(true);
    const user = await this.auth.getUser();
    // console.log(feedback);
    // console.log(user);

    const newFeedbackId = this.afs.createId();

    const feedbackDoc = this.afs.collection('feedback').doc(newFeedbackId).ref;

    const newFeedback = new Feedback();

    if (user) {
      newFeedback.uid = user.uid;
      newFeedback.email = user.email;
    }
    newFeedback.message = feedback.message;
    newFeedback.type = feedback.type;

    // console.log(newFeedback);

    const batch = firebase.firestore().batch();

    batch.set(feedbackDoc, {
      creationTime: firebase.firestore.FieldValue.serverTimestamp(),
      ...newFeedback,
    });

    batch.commit().then(() => {
      // console.log('feedback submitted');
      this._loading.next(false);
      this._done.next(true);
    });

    return new Promise(resolve => {
      resolve(true);
    });
  }

  feedbackClosed() {
    this._loading.next(false);
    this._done.next(false);
  }
}
