/* eslint-disable max-classes-per-file */
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Build } from '../../core/models/build';
import { speedDialFabAnimations } from './speed-dial-fab.animations';
import { AuthService } from '../../core/services/auth.service';
import { BuildService } from '../../core/services/build.service';
import { User } from '../../core/models/user';
import { BuildPickerSheetComponent } from '../build-picker-sheet/build-picker-sheet.component';

@Component({
  selector: 'app-speed-dial-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: speedDialFabAnimations,
})
export class SpeedDialFabComponent implements OnInit {
  fabButtons = [
    {
      icon: 'add',
      label: 'New build',
      command: 'onConsole()',
    },
    {
      icon: 'add',
      label: 'New step',
      command: 'onConsole()',
    },
  ];

  hasBuilds: boolean;

  numberOfBuilds: number;

  builds: Build[];

  currentUser: User;

  userId: string;

  buttons = [];

  fabTogglerState = 'inactive';

  constructor(
    //
    public auth: AuthService,
    private buildService: BuildService,
    private _router: Router,
    private _bottomSheet: MatBottomSheet,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.auth.getUser().then(async user => {
      if (user) {
        this.userId = user.uid;
        await this.buildService
          .getBuildsforUser(this.userId)
          .then(builds => {
            this.builds = builds;
            this.numberOfBuilds = this.builds.length;
            if (this.numberOfBuilds > 0) {
              this.hasBuilds = true;
            } else {
              this.hasBuilds = false;
            }
          })
          .then(() => {
            this.setButtons(this.numberOfBuilds);
          });
      }
    });
  }

  setButtons(buildsNumber: number): void {
    switch (buildsNumber) {
      case 0:
        this.fabButtons = [
          {
            icon: 'add',
            label: 'New build',
            command: 'build',
          },
        ];
        break;
      default:
        this.fabButtons = [
          {
            icon: 'add',
            label: 'New build',
            command: 'build',
          },
          {
            icon: 'add',
            label: 'New step',
            command: 'step',
          },
        ];
        break;
    }
  }

  showItems(): void {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems(): void {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  // eslint-disable-next-line class-methods-use-this
  handleClick(command: string): void {
    switch (command) {
      case 'step':
        if (this.numberOfBuilds > 1) {
          this._bottomSheet.open(BuildPickerSheetComponent, {
            data: { builds: this.builds },
          });
        } else {
          this._router.navigate([`create/step/${this.builds[0].id}`]);
        }
        break;
      case 'build':
        this._router.navigate([`create/build`]);
        break;

      default:
        this._router.navigate([`create/build`]);
        break;
    }
  }

  openBottomSheet(): void {
    this._bottomSheet.open(BuildPickerSheetComponent);
  }

  onToggleFab(): void {
    if (this.buttons.length) {
      this.hideItems();
    } else {
      this.showItems();
    }
    // this.buttons.length ? this.hideItems() : this.showItems();
  }
}
