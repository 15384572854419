<div class="controls" ngClass.xs="xs" *ngIf="auth.user$ | async as user; else showLogin">
  <button mat-button routerLink="/build/my-builds" routerLinkActive="active">My builds</button>
  <button mat-button routerLink="/create/build" fxHide.xs="true" routerLinkActive="active">New build</button>
  <button mat-button *ngIf="numberOfBuilds > 0" fxHide.xs="true" (click)="handleClick('step')"
    routerLinkActive="active">New
    step</button>
  <div class="menu" fxLayout="row">
    <button class="avatar-button" mat-button [matMenuTriggerFor]="menu">
      <img *ngIf="user.profileURL100; else noAvatar" class="avatar100" width="100px" src="{{ user.profileURL100 }}"
        alt="{{ user.displayName }}">
    </button>
    <mat-menu #menu="matMenu">
      <p style="padding: 0 16px;" class="mat-body-strong">{{ user.displayName }}</p>
      <mat-divider></mat-divider>
      <a mat-menu-item routerLink="/create/build">New build</a>
      <mat-divider></mat-divider>
      <a mat-menu-item [routerLink]="['/settings']">Account settings</a>
      <a mat-menu-item (click)="openFeedback()">Give feedback</a>
      <a mat-menu-item (click)="logout()">Logout</a>
    </mat-menu>
  </div>
</div>
<ng-template #showLogin>
  <div class="controls" ngClass.xs="xs">
    <span fxFlex></span>
    <div class="menu" fxLayout="row">
      <button mat-icon-button color="primary" class="avatar-button" aria-label="User profile icon"
        [matMenuTriggerFor]="menu">
        <mat-icon class="material-icons-outlined">account_circle</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item [routerLink]="['/account/signin']">Sign in</a>
        <a mat-menu-item [routerLink]="['/account/signup']">Create an account</a>
        <mat-divider></mat-divider>
        <a mat-menu-item (click)="openFeedback()">Give feedback</a>
      </mat-menu>
    </div>
  </div>
</ng-template>

<ng-template #noAvatar>
  <img class="avatar100" width="100px" src="assets/img/FPO-Image.png" alt="Profile Image">
</ng-template>