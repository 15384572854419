import { Component, OnInit, Input } from '@angular/core';
import { Build } from '../../../core/models/build';

@Component({
  selector: 'app-build-card',
  templateUrl: './build-card.component.html',
  styleUrls: ['./build-card.component.scss'],
})
export class BuildCardComponent implements OnInit {
  @Input() build: Build;

  constructor() { }

  ngOnInit() { }
}
