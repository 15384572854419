import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule, MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { A11yModule } from '@angular/cdk/a11y';
import { RouteReuseStrategy } from '@angular/router';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, DEBUG_MODE, ScreenTrackingService, CONFIG } from '@angular/fire/compat/analytics/';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';

import { NgAisInstantSearch, NgAisModule } from 'angular-instantsearch';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { QuillModule } from 'ngx-quill';
import { MatQuillModule } from './shared/mat-quill/mat-quill-module';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routes';
import { CustomRouteReuseStrategy } from './app.routes.strategy';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './builds/home/home.component';
import { HomeStepComponent } from './builds/home-step/home-step.component';
import { UserAuthComponent } from './shared/user-auth/user-auth.component';
import { DropzoneDirective } from './dropzone.directive';
import { NotfoundComponent } from './notfound/notfound.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { FormLayoutComponent } from './_layout/form-layout/form-layout.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { SiteHeaderComponent } from './_layout/site-header/site-header.component';

import { SpeedDialFabComponent } from './shared/speed-dial-fab/speed-dial-fab.component';

import { SearchModule } from './search/search.module';
import { FeedbackComponent } from './shared/feedback/feedback.component';
import { RedirectGuard } from './core/guards/redirect.guard';
import { RedirectComponent } from './redirect/redirect.component';
import { SearchOverlayComponent } from './search-overlay/search-overlay.component';
import { ShortPageLayoutComponent } from './_layout/short-page-layout/short-page-layout.component';
import { LightboxModule } from './lightbox/lightbox.module';
import { GalleryModule } from './gallery/gallery.module';


export const { firebaseConfig } = environment;

const toolbarOptions = [
  ['bold', { list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }], // toggled buttons
  ['link'],
  // ['blockquote', 'code-block'],

  // [{ header: 1 }, { header: 2 }], // custom button values
  // [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  // [{ direction: 'rtl' }], // text direction

  // [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }],
  // [{ align: [] }],

  // ['clean'], // remove formatting button
];

const bindings = {
  italic: {
    key: 'I',
    shortKey: true,
    handler(range, context) {
      // Handle shift+b
    },
  },
  underline: {
    key: 'U',
    shortKey: true,
    handler(range, context) {
      // Handle shift+b
    },
  },
};

const formats = [
  // 'background',
  'bold',
  // 'color',
  // 'font',
  // 'code',
  // 'italic',
  'link',
  // 'size',
  // 'strike',
  // 'script',
  // 'underline',
  // 'blockquote',
  // 'header',
  'indent',
  'list',
  // 'align',
  // 'direction',
  // 'code-block',
  // 'formula',
  // 'image'
  // 'video'
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserAuthComponent,
    DropzoneDirective,
    NotfoundComponent,
    AppLayoutComponent,
    SiteLayoutComponent,
    SiteHeaderComponent,
    SpeedDialFabComponent,
    FormLayoutComponent,
    SearchOverlayComponent,
    FeedbackComponent,
    RedirectComponent,
    HomeStepComponent,
    ShortPageLayoutComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AppRoutingModule,
    A11yModule,
    NgAisModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        toolbar: toolbarOptions,
      },
      formats,
    }),
    MatQuillModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
    FlexLayoutModule,
    LayoutModule,
    OverlayModule,
    InfiniteScrollModule,
    SearchModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatMenuModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTooltipModule,
    MatCardModule,
    MatProgressSpinnerModule,
    LightboxModule,
    GalleryModule
  ],
  exports: [],
  providers: [
    Title,
    AngularFireAuthGuard,
    RedirectGuard,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    ScreenTrackingService,
    {
      provide: CONFIG,
      useValue: {
        send_page_view: false,
      },
    },
    {
      provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
      useValue: {
        strokeWidth: 3,
        diameter: 50,
      },
    },
    // { provide: DEBUG_MODE, useValue: true },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule { }
