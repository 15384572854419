import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShareModalComponent, ShareModel } from '../share-modal/share-modal.component';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.scss'],
})
export class ShareButtonComponent implements OnInit {
  @Input() type: string;

  @Input() id: string;

  @Input() title: string;

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ShareModalComponent>) {}

  ngOnInit(): void {}

  openShareModal() {
    const dialogData = new ShareModel(this.type, this.id, this.title);
    this.dialogRef = this.dialog.open(ShareModalComponent, {
      width: '300px',
      autoFocus: false,
      data: dialogData,
    });
  }
}
