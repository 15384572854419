/* eslint-disable @typescript-eslint/ban-types */
import { Component, Inject, forwardRef, OnInit, Optional } from '@angular/core';
import { TypedBaseWidget, NgAisIndex, NgAisInstantSearch } from 'angular-instantsearch';
import connectPagination, {
  PaginationWidgetDescription,
  PaginationConnectorParams
} from 'instantsearch.js/es/connectors/pagination/connectPagination';
@Component({
  selector: 'app-search-pagination',
  templateUrl: './search-pagination.component.html',
  styleUrls: ['./search-pagination.component.scss'],
})
export class SearchPaginationComponent extends TypedBaseWidget<PaginationWidgetDescription, PaginationConnectorParams> {
  public state: PaginationWidgetDescription['renderState']
  // parentIndex?: NgAisIndex;

  // instantSearchInstance: NgAisInstantSearch;

  // public state: {
  //   pages: number[];
  //   currentRefinement: number;
  //   nbHits: number;
  //   nbPages: number;
  //   isFirstPage: boolean;
  //   isLastPage: boolean;
  //   refine: (value: number) => void;
  //   createURL: () => string;
  //   widgetParams: (value: string) => void;
  // };

  constructor(
    @Inject(forwardRef(() => NgAisIndex))

    @Optional()

    public parentIndex: NgAisIndex,

    @Inject(forwardRef(() => NgAisInstantSearch))

    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('SearchPaginationComponent');
  }

  ngOnInit() {
    this.createWidget(connectPagination, {
      padding: 1,
    });
    super.ngOnInit();
  }
}
