<div *ngIf="this.state">
    <div *ngIf="state.nbPages > 1" class="pagination">
        <button mat-icon-button (click)="state.refine(0)" [disabled]="state.isFirstPage">
            <mat-icon>first_page</mat-icon>
        </button>
        <button mat-icon-button (click)="state.refine(state.currentRefinement - 1)" [disabled]="state.isFirstPage">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-flat-button *ngFor="let page of state.pages" (click)="state.refine(page)"
            [disabled]="page === state.currentRefinement">
            <span [ngClass]="{'mat-body-strong':page === state.currentRefinement}">{{ page + 1 }}</span>
        </button>
        <button mat-icon-button (click)="state.refine(state.currentRefinement + 1)" [disabled]="state.isLastPage">
            <mat-icon>chevron_right</mat-icon>
        </button>
        <button mat-icon-button (click)="state.refine(state.nbPages - 1)" [disabled]="state.isLastPage">
            <mat-icon>last_page</mat-icon>
        </button>
</div>
</div>
