import { ChangeDetectorRef, Component, forwardRef, Inject, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { TypedBaseWidget, NgAisInstantSearch, NgAisIndex } from 'angular-instantsearch';
import connectRefinementList, {
  RefinementListWidgetDescription,
  RefinementListConnectorParams
} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';

@Component({
  selector: 'app-refinement-list',
  templateUrl: './search-assisted-navigation.component.html',
  styleUrls: ['./search-assisted-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchAssistedNavigationComponent extends TypedBaseWidget<RefinementListWidgetDescription, RefinementListConnectorParams> {
  public state: RefinementListWidgetDescription['renderState']; // Rendering options

  @ViewChild(MatExpansionPanel) accordion: MatExpansionPanel;

  constructor(
    @Inject(forwardRef(() => NgAisIndex))

    @Optional()

    public parentIndex: NgAisIndex,

    @Inject(forwardRef(() => NgAisInstantSearch))

    public instantSearchInstance: NgAisInstantSearch,

    private cd: ChangeDetectorRef,
  ) {
    super('SearchAssistedNavigationComponent');
  }

  ngOnInit() {
    this.createWidget(connectRefinementList, {
      // instance options
      attribute: 'type',
      sortBy: ['name:asc']
    });

    super.ngOnInit();
  }

  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    this.cd.detectChanges();
  }
}
