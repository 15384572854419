import { Component, Inject, forwardRef, Optional, Input } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { TypedBaseWidget, NgAisInstantSearch, NgAisIndex } from 'angular-instantsearch';

import connectClearRefinements, {
  ClearRefinementsWidgetDescription,
  ClearRefinementsConnectorParams
} from 'instantsearch.js/es/connectors/clear-refinements/connectClearRefinements';

@Component({
  selector: 'app-search-clear-assisted-navigation',
  templateUrl: './search-clear-assisted-navigation.component.html',
  styleUrls: ['./search-clear-assisted-navigation.component.scss']
})
export class SearchClearAssistedNavigationComponent extends TypedBaseWidget<ClearRefinementsWidgetDescription, ClearRefinementsConnectorParams> {
  public state: ClearRefinementsWidgetDescription['renderState']; // Rendering options

  @Input() accordion: MatExpansionPanel;

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
  ) {
    super('SearchClearAssistedNavigationComponent')
  }

  ngOnInit() {
    this.createWidget(connectClearRefinements, {
      // instance options
    });
    super.ngOnInit();
  }

  clearFilters() {
    this.accordion.close();
    this.state.refine();
  }
}
