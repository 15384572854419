export class Feedback {
  id?: string;

  message?: string;

  type: string;

  uid?: string;

  email?: string;

  creationTime?: any;
}
