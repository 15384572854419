/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { Injectable, NgZone, Optional, SkipSelf } from '@angular/core';
// import { auth, User as firebaseUser, functions } from 'firebase/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, unlink } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, first, map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user'; // optional
import { ProfileThumb } from '../models/profileThumb';
import { BreakpointService } from './breakpoint.service';

export interface UserName {
  uid?: string;
  reserved: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user$: Observable<User>;

  userFirebase$: Observable<firebase.User>;

  uid: string;

  // userDetails: Observable<UserDetails>;
  public email: string;

  constructor(
    private afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    private router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public http: HttpClient,
    public breakpointService: BreakpointService,
    @Optional() @SkipSelf() parent?: AuthService,
  ) {
    if (parent) {
      throw Error(
        `[GuardedSingletonService]: trying to create multiple instances,
        but this service should be a singleton.`,
      );
    }

    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this.uid = user.uid;
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
          // eslint-disable-next-line no-else-return
        }
        return of(null);
      }),
    );

    this.userFirebase$ = this.afAuth.user;
  }

  async reloadUser(): Promise<firebase.User> {
    return this.getUserFB().then(user => {
      user.reload();
      return user;
    });
  }

  getAuth() {
    return this.afAuth;
  }

  // used in profile page
  loadUser(): Observable<User> {
    return this.user$;
  }

  async getUser(): Promise<User> {
    // return this.afAuth.authState.pipe(first()).toPromise();
    return this.user$.pipe(first()).toPromise();
  }

  getUserFB(): Promise<firebase.User> {
    return this.userFirebase$.pipe(first()).toPromise();
  }

  async isVerified(): Promise<boolean> {
    const user = await this.getUserFB().then(fbUser => fbUser);
    if (user) {
      // reload the user to get the freshest data.
      await user.reload();
    }
    return user.emailVerified;
  }

  public async signIn(email: string, password: string) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        // Signed in
        // eslint-disable-next-line prefer-destructuring
        const user = userCredential.user;
        this.updateUserData(user).then(_ => {
          if (!user.emailVerified) {
            this.router.navigate(['account/verification-needed']);
          }
        });
      })
      .catch(error => {
        // console.log('error', error.code);
        const errorCode = error.code;
        const errorMessage = error.message;
        throw new Error(error);
        // return Promise.reject(throw new Error(error);
      });
  }

  async googleSignIn() {
    const provider = new GoogleAuthProvider();
    let credential;
    if (!this.breakpointService.isMobile) {
      credential = await this.afAuth.signInWithPopup(provider);
    } else {
      await this.afAuth.signInWithRedirect(provider);
      await this.afAuth.getRedirectResult().then(result => {
        // The firebase.User instance:
        let user = result.user;
        console.log('user: ', user);
        // The Facebook firebase.auth.AuthCredential containing the Facebook
        // access token:
        credential = result.credential;
        // As this API can be used for sign-in, linking and reauthentication,
        // check the operationType to determine what triggered this redirect
        // operation.
        let operationType = result.operationType;
        this.router.navigate(['/']);
      }, error => {
        // The provider's account email, can be used in case of
        // auth/account-exists-with-different-credential to fetch the providers
        // linked to the email:
        let email = error.email;
        // The provider's credential:
        credential = error.credential;
        // In case of auth/account-exists-with-different-credential error,
        // you can fetch the providers using this:
        if (error.code === 'auth/account-exists-with-different-credential') {
          this.afAuth.fetchSignInMethodsForEmail(email).then(providers => {
            // The returned 'providers' is a list of the available providers
            // linked to the email address. Please refer to the guide for a more
            // complete explanation on how to recover from this error.
          });
        }
      });

    }



    if (credential.additionalUserInfo.isNewUser) {
      // console.log('new user');
      this.router.navigate([`/account/set-user-name`]);
      // this.router.navigate(['']);
      return this.initializeGoogleUserData(credential.user);
    }


    // console.log('Credential', credential);
    // console.log('isNewUser', credential.additionalUserInfo.isNewUser);
    // if this is a brand new user, create/update the UserData Record





    // otherwise the user exists
    // this.router.navigate([`/`]);
    return Promise.resolve(true);
  }

  async disconnectGoogle(): Promise<void> {
    const auth = getAuth();
    const providerId = 'google.com';
    return unlink(auth.currentUser, providerId)
      .then(() => {
        return Promise.resolve();
      })
      .catch(error => {
        return Promise.reject();
      });
  }

  // async facebookSignIn() {
  //   const provider = new FacebookAuthProvider();
  //   const credential = await this.afAuth.signInWithPopup(provider).catch(function(error) {
  //     // An error happened.
  //     if (error.code === 'auth/account-exists-with-different-credential') {
  //       // Step 2.
  //       // User's email already exists.
  //       // The pending Facebook credential.
  //       var pendingCred = error.credential;
  //       // The provider account's email address.
  //       var email = error.email;
  //       // Get sign-in methods for this email.
  //       this.afAuth.fetchSignInMethodsForEmail(email).then(methods => {
  //         // Step 3.
  //         // If the user has several sign-in methods,
  //         // the first method in the list will be the "recommended" method to use.
  //         if (methods[0] === 'password') {
  //           // Asks the user their password.
  //           // In real scenario, you should handle this asynchronously.
  //           var password = promptUserForPassword(); // TODO: implement promptUserForPassword.
  //           this.afAuth.signInWithEmailAndPassword(email, password).then(function(result) {
  //             // Step 4a.
  //             return result.user.linkWithCredential(pendingCred);
  //           }).then(function() {
  //             // Facebook account successfully linked to the existing Firebase user.
  //             this.router.navigate('/');
  //           });
  //           return;
  //         }
  //         // All the other cases are external providers.
  //         // Construct provider object for that provider.
  //         // TODO: implement getProviderForProviderId.
  //         var provider = getProviderForProviderId(methods[0]);
  //         // At this point, you should let the user know that they already have an account
  //         // but with a different provider, and let them validate the fact they want to
  //         // sign in with this provider.
  //         // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
  //         // so in real scenario you should ask the user to click on a "continue" button
  //         // that will trigger the signInWithPopup.
  //         this.afAuth.signInWithPopup(provider).then(result => {
  //           // Remember that the user may have signed in with an account that has a different email
  //           // address than the first one. This can happen as Firebase doesn't control the provider's
  //           // sign in flow and the user is free to login using whichever account they own.
  //           // Step 4b.
  //           // Link to Facebook credential.
  //           // As we have access to the pending credential, we can directly call the link method.
  //           result.user.linkAndRetrieveDataWithCredential(pendingCred).then(usercred => {
  //             // Facebook account successfully linked to the existing Firebase user.
  //             // goToApp();
  //             this.router.navigate('/');
  //           });
  //         });
  //       });
  //     }
  //   });

  //   console.log(credential);
  //   // return this.updateUserData(credential.user);
  //   // if (credential.additionalUserInfo.isNewUser) {
  //   //   // console.log('new user');
  //   //   this.router.navigate([`/account/set-user-name`]);
  //   //   // this.router.navigate(['']);
  //   //   return this.initializeGoogleUserData(credential.user);
  //   // }
  //   return Promise.resolve(true);
  // }

  promptUserForPassword() {

  }

  checkUserName(username: string): Observable<boolean> | null {
    const usernameToCheck = username.toLowerCase();
    let exists: Observable<boolean>;
    return this.afs
      .collection('usernames')
      .doc(usernameToCheck)
      .snapshotChanges()
      .pipe(
        map(action => {
          if (action.payload.exists === false) {
            return true;
          }
          return false;
        }),
      );
    return exists;
  }

  resetPasswordInit(email: string) {
    // const authFromFireBase = firebase.auth();
    return (
      this.afAuth
        .sendPasswordResetEmail(email)
        // .then(() => console.log('email sent'))
        .catch(error => this.handleError(error))
    );
  }

  reauthenticate = async currentPassword => {
    const user = this.afAuth.currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential((await user).email, currentPassword);
    return (await user).reauthenticateWithCredential(cred);
  };

  updatePW(currentPassword, newPassword): Promise<any> {
    return this.reauthenticate(currentPassword)
      .then(async () => {
        const user = this.afAuth.currentUser;
        (await user)
          .updatePassword(newPassword)
          .then(() => {
            // console.log('Password updated!');
            return true;
          })
          .catch(error => {
            // console.error(error);
            return error;
          });
      })
      .catch(error => {
        // console.error(error);
        return this.handleError(error);
      });
  }

  async isOwner(id: string): Promise<boolean> {
    // if (this.afAuth.currentUser) {
    //   // const user = this.afAuth.auth.currentUser;
    //   if ((await this.afAuth.currentUser).uid === id) {
    //     return true;
    //   }
    //   return false;
    // }
    // return false;
    const user = this.getUser();
    // console.log('user', user);
    return false;
  }

  // Returns true if user is logged in
  get authenticated(): boolean {
    return this.afAuth.currentUser !== null;
  }

  /**
   * Sign up new users via email and password.
   * After that the user should verify and confirm an email sent via the firebase
   *
   * @param name - the name if the new user
   * @param email - the email if the new user
   * @param password - the password if the new user
   * @returns
   */
  public async signUpCloudFx(formValues): Promise<any> {
    const createUser = firebase.functions().httpsCallable('createUser');
    return createUser(formValues)
      .then(result => {
        if (result.data.success) {
          return this.afAuth.signInWithEmailAndPassword(formValues.email, formValues.password);
        }
        throw new Error('Something happened');
      })
      // .then(credential => {
      //   return credential.user.sendEmailVerification();
      // })
      .then(() => this.router.navigate(['account/verify-email']))
      .catch(err => {
        throw new Error(err.message);
      });
  }

  /**
   * Sets the display name for the user
   * This is used when initializing a new user through Google log in
   *
   * @param name - the display name provided by the user
   */

  public async setUserName(name: string) {
    // console.log('display name', name);
    const displayName = name.toLowerCase();
    const batch = this.afs.firestore.batch();
    const user = await this.getUserFB();
    const userNameRef = this.afs.doc(`usernames/${displayName}`).ref;
    const userNameData = {
      uid: user.uid,
    };
    const userRef: DocumentReference<User> = this.afs.doc<User>(`users/${user.uid}`).ref;
    const data = {
      displayName: name,
    };

    batch.set(userRef, { ...data }, { merge: true });
    batch.set(userNameRef, { ...userNameData });
    return batch.commit().then(_ => {
      user.updateProfile({ displayName: name });
      return Promise.resolve();
    });
  }

  public async signUp(formValues) {
    let userNameDocRef;
    return this.reserveUserName(formValues.displayName).then(docRef => {
      userNameDocRef = docRef;
      return this.afAuth
        .createUserWithEmailAndPassword(formValues.email, formValues.password)
        .then(credential =>
          credential.user
            .updateProfile({ displayName: formValues.displayName, photoURL: '' })
            .then(() => this.setDefaultValues(credential.user))
            .then(() => this.updateUserData(credential.user))
            .then(() => this.router.navigate(['account/verify-email']))
            .catch(err => {
              return err;
            }),
        )
        .catch(error => {
          // console.log('error', error.code);
          this.unReserveName(formValues.displayName);
          switch (error.code) {
            case 'name-already-exists':
              throw new Error(`This user name already exists. Please use a different name.`);
            case 'auth/email-already-exists ':
              throw new Error(`The email address is already in use by another account.`);
            case 'auth/email-already-in-use':
              throw new Error(`The email address is already in use by another account.`);
            default:
              throw new Error(`An unknown error has occured. Pleae try again.`);
          }
        });
    });
  }

  public reserveUserName(displayName: string): Promise<AngularFirestoreDocument<any>> {
    return new Promise<AngularFirestoreDocument<any>>((resolve, reject) => {
      let userName: Observable<any>;
      const userNameDoc = this.afs.collection('usernames').doc(displayName.toLowerCase());
      // eslint-disable-next-line prefer-const
      userName = userNameDoc.snapshotChanges();
      userName.pipe(take(1)).subscribe(res => {
        if (res.payload.exists) {
          Promise.reject(new Error('UserName is taken')).then(
            function () {
              // not called
            },
            function (error) {
              console.error(error); // Stacktrace
            },
          );
        } else {
          userNameDoc.set({ reserved: true }).then(_ => resolve(userNameDoc));
        }
      });
    });
  }

  public unReserveName(displayName: string) {
    const userNameDoc = this.afs.collection('usernames').doc(displayName.toLowerCase());
    userNameDoc.delete();
  }

  // sets default values when account is initially created.
  // updates userName to uid instead of "reserved"
  public setDefaultValues(user: firebase.User) {
    const batch = this.afs.firestore.batch();
    const userNameRef = this.afs.doc(`usernames/${user.displayName.toLowerCase()}`).ref;
    const userNameData = {
      uid: user.uid,
    };
    const userRef: DocumentReference<User> = this.afs.doc<User>(`users/${user.uid}`).ref;
    const data = {
      uid: user.uid,
      email: user.email,
      profileURL: 'assets/img/FPO-Image.png',
      profileURL100: 'assets/img/FPO-Image.png',
      profileURL200: 'assets/img/FPO-Image.png',
      profileURL400: 'assets/img/FPO-Image.png',
    };
    batch.set(userRef, { ...data }, { merge: true });
    batch.set(userNameRef, { ...userNameData });
    batch.commit();
    // return userRef.set(data, { merge: true });
  }

  async updateUser(form) {
    const user = this.afAuth.currentUser;

    (await user)
      .updateProfile({ displayName: form.displayName })
      .then(async () => {
        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${(await user).uid}`);
        const data = {
          uid: (await user).uid,
          email: (await user).email,
          displayName: form.displayName,
        };
        userRef.set(data, { merge: true });
        // .then(() => this.setNamesToBuilds())
        // .catch(err => console.error(err));
      })
      .catch(error => {
        // console.error(error);
      });
  }

  async updateUserNotificationPrefernces(user: User) {
    // const user = this.afAuth.currentUser;

    const userDoc: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

    userDoc.update({
      ...user,
    });
  }

  public async sendVerificationEmail(user): Promise<void> {
    const fbUser = await this.getUserFB();
    fbUser
      .sendEmailVerification()
      .then(() => true)
      .catch(err => err);
  }

  public async delete(): Promise<any> {
    return (await this.afAuth.currentUser).delete();
  }

  public deleteUser(password): Promise<any> {
    // const user = this.afAuth.auth.currentUser;
    return this.reauthenticate(password).then(async cred => {
      const user = await this.afAuth.currentUser;
      await user.delete();
      // user.updatePassword(newPassword).then(() => {
      // console.log('User deleted');
      // })
      // .catch(error => { console.error(error); });
    });
    // .catch(error => this.handleError(error));
  }

  /**
   * Updates the user document with the updated profile image URLs
   *
   * @remarks
   * At the end it also propogates the image urls across all the user associated entities like builds and comments
   *
   * @param images - a ProfileThumb object
   */
  public async updateUserProfileImage(images: ProfileThumb) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${(await this.afAuth.currentUser).uid}`);
    const data = {
      uid: (await this.afAuth.currentUser).uid,
      email: (await this.afAuth.currentUser).email,
      profileURL: images.url,
      profileURL100: images.url100,
      profileURL200: images.url200,
      profileURL400: images.url400,
    };
    // console.log(data);

    userRef.set(data, { merge: true });
    this.propogateProfileImages(data);
  }

  /**
   * Logs the user out and sends them to the home page
   */

  logout() {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['']);
    });
  }

  private async initializeGoogleUserData(user): Promise<void> {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    const data = {
      uid: user.uid,
      email: user.email,
      // displayName: user.displayName,
      emailVerified: user.emailVerified,
      profileURL: user.photoURL,
      profileURL100: user.photoURL,
      profileURL200: user.photoURL,
      profileURL400: user.photoURL,
      notifyNewComment: true,
      notifyNewFollower: true,
      notifyNewContentFromFollowed: true,
      notifyNewCommentDiscussing: true,
    };
    return userRef.set(data, { merge: true }).then(_ => {
      return Promise.resolve();
    });
  }

  private async updateUserData(user) {
    // console.log(user);
    // console.log('emailVerified', user.emailVerified);
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      emailVerified: user.emailVerified,
      // profileURL: 'assets/img/FPO-Image.png',
      // profileURL100: 'assets/img/FPO-Image.png',
      // profileURL200: 'assets/img/FPO-Image.png',
      // profileURL400: 'assets/img/FPO-Image.png',
    };
    userRef.set(data, { merge: true });
    // return true;
  }

  propogateProfileImages(data) {
    const sendData = firebase.functions().httpsCallable('propogateProfileImages');
    sendData(data)
      .then(result => {
        // Read result of the Cloud Function.
        // console.log(result);
      })
      .catch(error => {
        // Getting the Error details.
        const { code } = error;
        const { message } = error;
        const { details } = error;
        // console.log(code);
        // console.log(message);
        // console.log(details);
      });
  }

  getUserById(id: string): Promise<User> {
    const user = firebase.functions().httpsCallable('getUser');
    return user({ uid: id }).then(result => {
      // Read result of the Cloud Function.
      // console.log(result.data.email);
      const userEmail = result.data.email;
      return result.data;
    });

    // return;
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    // console.error(error);
    throw error;
  }
}
