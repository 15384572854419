import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';

import { ResultsComponent } from './results/results.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SearchRoutingModule } from './search.routes';
import { SharedModule } from '../shared/shared.module';
import { SearchPaginationComponent } from '../shared/search-pagination/search-pagination.component';
import { SearchBoxComponent } from './search-box/search-box.component';


@NgModule({
  declarations: [ResultsComponent, SearchResultComponent, SearchPaginationComponent, SearchBoxComponent],
  imports: [
    CommonModule,
    RouterModule,
    SearchRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatExpansionModule,
  ],
  exports: [SearchResultComponent],
})
export class SearchModule { }
