import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { cleanTitle } from 'src/app/_helpers/string-hypenator';
import { BreakpointService } from '../../core/services/breakpoint.service';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
})
export class SearchResultComponent implements OnInit {
  @Input() result;

  @Output() clicked: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    //
    private router: Router,
    public breakpointService: BreakpointService,
  ) { }

  ngOnInit(): void { }

  goToStep(id: string, title: string): Promise<boolean> {
    return this.router.navigate([`/step/${title}.${id}`]);
  }

  goToBuild(id: string, title: string): Promise<boolean> {
    return this.router.navigate([`/build/${title}.${id}`]);
  }

  goToItem(type: string, id: string, title: string) {
    this.clicked.emit('clicked');
    const cleanedTitle = cleanTitle(title)
    // this.closeOverlay();
    switch (type) {
      case 'build':
        this.goToBuild(id, cleanedTitle);
        break;
      case 'step':
        this.goToStep(id, cleanedTitle);
        break;
      default:
        break;
    }
  }
}
