import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {
  url: any;

  constructor(
    private title: Title,
    analytics: AngularFireAnalytics,
    private router: Router,
  ) {
    if (this.router.getCurrentNavigation().extras.state !== undefined) {
      this.url = this.router.getCurrentNavigation().extras.state.name;
      analytics.logEvent('404_loaded', { attemptedURL: this.url });
    } else {
      analytics.logEvent('404_loaded', { attemptedURL: 'unknown' });
    }
    // console.log('route:', this.url);
    // console.log('route:', route);
  }

  ngOnInit() {
    // console.log(this.route.snapshot.data['title']);
    this.title.setTitle(`BuildString | Not Found`);
  }
}
