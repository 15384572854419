import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'app-build-picker-sheet',
  templateUrl: './build-picker-sheet.component.html',
  styleUrls: ['./build-picker-sheet.component.scss'],
})
export class BuildPickerSheetComponent {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BuildPickerSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _router: Router,
  ) {}

  // eslint-disable-next-line class-methods-use-this
  newStep(buildId: string, event: MouseEvent): void {
    // console.log(buildId);
    this._bottomSheetRef.dismiss();
    event.preventDefault();
    this._router.navigate([`/create/step/${buildId}`]);
  }

  // const builds = this.data.
  openLink(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
