/* eslint-disable max-classes-per-file */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { cleanTitle } from 'src/app/_helpers/string-hypenator';

export class ShareModel {
  constructor(public type: string, public id: string, public title: string) { }
}

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss'],
})
export class ShareModalComponent implements OnInit {
  baseURL = 'https://www.buildstring.com/';

  link: string;

  constructor(
    public dialogRef: MatDialogRef<ShareModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShareModel,
    private _snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.link = this.getLink();
  }

  getLink(): string {
    console.log(this.data);
    const cleanedTitle = cleanTitle(this.data.title)

    let link: string;
    switch (this.data.type) {
      case 'build': {
        link = `${this.baseURL}build/${cleanedTitle}.${this.data.id}`;
        break;
      }
      case 'step': {
        link = `${this.baseURL}step/${cleanedTitle}.${this.data.id}`;
        break;
      }
      default: {
        link = `${this.baseURL}build/${cleanedTitle}.${this.data.id}`;
        break;
      }
    }
    return link;
  }

  shareEmail() {
    const mailLink = `mailto:?body=${this.link}`;
    window.open(mailLink);
    this.dialogRef.close(true);
  }

  shareTwitter() {
    let url = 'https://twitter.com/intent/tweet';
    url += `?url=${this.link}`;
    url += `&via=BuildString`;
    url += `&text=${this.data.title}`;
    window.open(url, '_blank');
    this.dialogRef.close(true);
  }

  shareReddit() {
    let url = `https://www.reddit.com/submit`;
    url += `?url=${this.link}`;
    url += `&title=${this.data.title}`;
    url = encodeURI(url);
    window.open(url, '_blank');
    this.dialogRef.close(true);
  }

  shareFacebook() {
    let url = `https://www.facebook.com/sharer/sharer.php?`;
    let suffix = `u=${this.link}`;
    suffix = encodeURI(suffix);
    url += suffix;
    url += `&display=popup`;
    window.open(url, '_blank');
    this.dialogRef.close(true);
  }

  // window.open(url,'_blank');

  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.link).then(_ => {
      /* clipboard successfully set */

      const message = 'Link copied to clipboard';
      const action = 'Dismiss';
      this._snackBar.open(message, action);
      this.dialogRef.close(true);
    });
  }
}
