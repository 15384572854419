// https://blog.thoughtram.io/angular/2017/11/20/custom-overlays-with-angulars-cdk.html
import { Component, HostListener, ViewChild, ElementRef, OnInit } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { Router } from '@angular/router';
// import { InstantSearchConfig } from 'angular-instantsearch/instantsearch/instantsearch';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchService } from '../core/services/search.service';
import { environment } from '../../environments/environment';
import { SearchOverlayRef } from './search-overlay-ref';

const searchClient = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);
// const searchClient = '';

// Keycode for ESCAPE
const ESCAPE = 27;

@Component({
  selector: 'search-overlay',
  templateUrl: './search-overlay.component.html',
  styleUrls: ['./search-overlay.component.scss'],
})
export class SearchOverlayComponent implements OnInit {
  // Listen on keydown events on a document level
  @HostListener('document:keydown', ['$event']) private handleKeydown(event: KeyboardEvent) {
    // console.log(event.key);

    if (event.key === 'Escape') {
      this.closeOverlay();
    }
    if (event.key === 'Enter') {
      this.viewMore();
    }
  }

  @ViewChild('box', { static: true })
  _el: ElementRef;

  query = '';

  searchForm: FormGroup;

  config = {
    searchClient,
    indexName: environment.algolia.allindex,
  };

  searchHits = 10;

  valueEmittedFromChildComponent = '';

  constructor(
    private router: Router,
    public dialogRef: SearchOverlayRef,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this._el.nativeElement.focus();
    this.searchForm = this.formBuilder.group({
      query: [this.query],
    });
  }

  onQuery($event) {
    this.query = $event.target.value;
  }

  get searchParameters() {
    return {
      query: this.query,
      filters: `isPrivate:false`,
      hitsPerPage: this.searchHits,
      distinct: true,
    };
  }

  viewMore() {
    this.closeOverlay();
    this.router.navigate(['/results'], { queryParams: { search_query: this._el.nativeElement.value } });
  }

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    this.closeOverlay();
  }

  closeOverlay() {
    this.dialogRef.close();
  }
}
