import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'secondsToRelTime' })
export class SecondsToRelTimePipe implements PipeTransform {
  relativeDate: DateTime;

  now: DateTime;
  // seconds to use "Just now" for

  transform(value: any, ...args: any[]) {
    const justNowString = 'Just now';
    const seconds = Number(value);
    this.now = DateTime.local();
    this.relativeDate = DateTime.fromSeconds(seconds);
    const diff = this.now.diff(this.relativeDate);
    // less than 1 minute old
    if (diff.milliseconds < 60001) {
      return justNowString;
    }
    const relDate = this.relativeDate.toRelative();
    return relDate;
  }
}
