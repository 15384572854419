<div mat-dialog-content class="sign-in-form" ngClass.xs="xs">
  <p class="mat-title">Sign in to BuildString</p>
  <mat-card class="mat-elevation-z0 noRadius">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">
      <mat-form-field appearance="fill">
        <mat-label>Email address</mat-label>
        <input matInput type="email" formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password">
        <!-- <mat-error *ngIf="password.invalid && (password.dirty || password.touched)">
              <div *ngIf="password.errors.required">Password is <strong>required</strong></div>
          </mat-error> -->
      </mat-form-field>
      <button mat-flat-button type="submit" color="primary" [disabled]="!loginForm.valid" class="basic-btn">Log
        in</button>
      <mat-error *ngIf="error" class="formError">{{error}}</mat-error>
    </form>
    <div class="auth-divider"><span class="auth-divider-text">OR</span></div>
    <div class="external-login">
      <a mat-flat-button color="primary" (click)="googleLogin()">
        <img class="auth-google-logo" src="../../../assets/auth-providers/google.svg" alt="Google logo">
        <span>Sign in with Google</span>
      </a>
      <!-- <button mat-flat-button color="primary" (click)="googleLogin()">Sign in with Google</button> -->
    </div>
    <button mat-button type="button" mat-dialog-close routerLink="/account/signup">Create an account</button>
    <button mat-button type="button" mat-dialog-close routerLink="/account/forgot-password">Forgot password?</button>
  </mat-card>
</div>