import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SearchService } from '../../core/services/search.service';
import { environment } from '../../../environments/environment';
import { cleanTitle } from 'src/app/_helpers/string-hypenator';

const searchClient = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  query = '';

  currentQuery = '';

  searchForm: FormGroup;

  config = {
    searchClient,
    indexName: environment.algolia.allindex,
  };

  constructor(
    public search: SearchService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.currentQuery = this.route.snapshot.queryParamMap.get("search_query")
    // console.log(this.route.snapshot.queryParamMap.get("search_query"));
    this.searchForm = this.formBuilder.group({
      query: [this.query],
    });
  }

  clearQuery() {
    this.query = '';
    this.searchForm.reset();
  }

  goToBuild(id: string, title: string): Promise<boolean> {
    this.clearQuery();
    return this.router.navigate([`/build/${title}.${id}`]);
  }

  goToStep(id: string, title: string): Promise<boolean> {
    this.clearQuery();
    return this.router.navigate([`/step/${title}.${id}`]);
  }

  goToItem(type: string, id: string, title: string) {
    this.clearQuery();
    const cleanedTitle = cleanTitle(title)
    switch (type) {
      case 'build':
        this.goToBuild(id, cleanedTitle);
        break;
      case 'step':
        this.goToStep(id, cleanedTitle);
        break;
      default:
        break;
    }
  }

  onQuery($event) {
    // console.log(searchClient);
    this.query = $event.target.value;
  }

  get searchParameters() {
    return {
      query: this.query,
      filters: `isPrivate:false`,
      page: 0,
      hitsPerPage: 10,
      distinct: true,
    };
  }

  viewMore() {
    if (this.currentQuery == this.query) {
      this.clearQuery();
    } else {
      this.router.navigate(['/results'], { queryParams: { search_query: this.query } });
    }
  }
}
