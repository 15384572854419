import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import algoliasearch from 'algoliasearch';
import { environment } from '../../../environments/environment';

const searchClient = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);
const index = searchClient.initIndex(environment.algolia.allindex);
const searchIndexName = environment.algolia.allindex;
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
  urlQuery: string;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
  ) { }

  config = {
    indexName: environment.algolia.allindex,
    searchClient,
    routing: {
      stateMapping: {
        stateToRoute(uiState) {
          const indexUiState = uiState[searchIndexName];
          return {
            search_query: indexUiState.query,
          }
        },
        routeToState(routeState) {
          return {
            [searchIndexName]: {
              query: routeState.search_query,
            },
          };
        },
      },
    }
  }

  get searchParameters() {
    console.log(this.urlQuery);
    let index = this.config.indexName
    return {
      query: this.urlQuery,
      filters: `isPrivate:false`,
      hitsPerPage: 10,
      distinct: true,

    };
  }
  searchHits: any;

  public ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.urlQuery = params.search_query;
      });
    this.route.data.subscribe(data => {
      this.title.setTitle(`${this.urlQuery} - ${data.title} | BuildString`);
    });
  }
}

