import { MediaMatcher } from '@angular/cdk/layout';
import algoliasearch from 'algoliasearch/lite';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { LinkService } from './core/services/link.service';

const searchClient = algoliasearch(environment.algolia.appId, environment.algolia.apiKey);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  APP_TITLE = 'BuildString';

  SEPARATOR = ' | ';

  // stepIndexName: environment.algolia.stepindex,
  mobileQuery: MediaQueryList;

  config = {
    indexName: environment.algolia.allindex,
    searchClient,
  };

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public titleService: Title,
    private readonly linkService: LinkService,
  ) {
    linkService.startRouteListener();
    this.mobileQuery = media.matchMedia('(max-width: 599px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    const cc = window as any;
    cc.cookieconsent.initialise({
      palette: {
        popup: { background: '#000000' },
        button: {
          background: '#000000',
          text: '#FFFFFF',
        },
      },
      theme: 'edgeless',
      content: {
        message: 'This website uses cookies to ensure you get the best experience on our website.',
        dismiss: 'Dismiss',
        link: 'Learn more',
        href: '/privacy',
      },
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        map(route => route.snapshot),
        map(snapshot => {
          if (snapshot.data.title) {
            return snapshot.data.title;
          }
          return false;
        }),
      )
      .subscribe(pathString => {
        if (pathString) {
          this.titleService.setTitle(`${pathString}`);
        } else {
          this.titleService.setTitle(`${this.APP_TITLE}`);
        }
      });
  }
}
